import React, { CSSProperties } from "react";

export interface ButtonProps {
    className?: string,
    style?: CSSProperties,
    // Size
    size?: "xs" | "sm" | "md" | "lg" | "xl", // If defined, boolean sizes are ignored
    xs?: boolean,
    sm?: boolean,
    md?: boolean,
    lg?: boolean,
    xl?: boolean,
    // Btn type
    type?: "default" | "info" | "success" | "warning" | "danger", // If defined, boolean types are ignored
    default?: boolean,
    info?: boolean,
    success?: boolean,
    warning?: boolean,
    danger?: boolean,
    title?: string,

    submit?: boolean,
    active?: boolean,
    disabled?: boolean,
    onClick?: () => void,
}

const Button: React.FunctionComponent<ButtonProps> = function (props) {
    let className = "btn";
    if (props.className) {
        className += " " + props.className;
    }
    if (props.type) {
        className += " btn-" + props.type;
    } else {
        let type = ["default", "info", "success", "warning", "danger"].find((key) => (key in props));
        if (type) {
            className += " btn-" + type;
        }
    }
    if (props.size) {
        className += " btn-" + props.size;
    } else {
        let size = ["xs", "sm", "md", "lg", "xl"].find((key) => (key in props && (props as any)[key]));
        if (size) {
            className += " btn-" + size;
        }
    }
    if (props.active) {
        className += " active";
    }
    if (props.disabled) {
        className += " disabled";
    }
    return (
        <button
            type={props.submit ? "submit" : "button"}
            className={className}
            style={props.style}
            aria-label={props.title}
            onClick={() => {
                if (!props.disabled && props.onClick) {
                    props.onClick();
                }
            }}
        >
            {props.children}
        </button>
    )
}

export default Button;
