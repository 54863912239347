
const english = {
    nav: {
        product: { dockington: true },
        pricing: "Pricing",
        faqs: "FAQs",
        reports: { dreports: true },
        testimonials: "Testimonials",
        about: "About",
        blog: "Blog",
        signup: "Sign up",
        software: "The management software",
        forWhom: "For whom",
        why: "Why",
        whoAreWe: "Who are we?",
        pricing: "Pricing"
    },
    hellobar: {
        text: [ "Download your FREE ", { dreports: true }, ", the program that does your company's statistics for you." ],
        action: "GIMME!",
    },
    main: {
        head: {
            title: "The management software for precision mechanical workshops - Dockington",
            description: "The management software thought specifically for the precision mechanical workshops, which simplifies the management of the orders (both sales and 3rd party) and the creation of transport documents and electronic invoices.",
            keywords: [
                "management software for precision mechanical workshops",
                "order management software",
                "transport document software"
            ],
        },
        grab: {
            tagline: {
                title: "The management software for those who",
                subtitle: [ "don't have time to waste", "do, not talk", "work hard and smart" ],
            }
        },
        accessNow: {
            title: ["Access ", { dockington: true }, " and get all you need, but nothing extra."],
            subtitle: "Because sometimes LESS IS MORE.",
            cards: [
                {
                    icon: "cube",
                    above: { text: "7", highlight: true },
                    below: "modules"
                },
                {
                    icon: "file-alt",
                    above: { text: "Unlimited", highlight: true },
                    below: "documents"
                },
                {
                    icon: { icon: "cloud-upload-alt", style: { width: "1em" } },
                    above: { text: "Daily", highlight: true },
                    below: "backups"
                },
                {
                    icon: [{ icon: "phone", flip: "horizontal" }, "sms", "envelope"],
                    above: { text: "24/7", highlight: true },
                    below: "support"
                },
            ],
        },
        makeYourOwn: {
            title: ["Make your own ", { dockington: true }, "."],
            subtitle: "Try all the modules for free, then pick what you need and forget about the rest.",
            modules: [
                {
                    module: "Module 1",
                    title: "Contacts",
                    content: {
                        text: "Insert your contacts’ info once and never type them again!",
                        points: [
                            "You’ll have them suggested for you whenever you create a document.",
                            "You’ll have all the fields autocompleted for you once you select one.",
                            "You’ll be able to find all the documents related to the contact in just a few seconds",
                        ],
                    },
                },
                {
                    module: "Module 2",
                    title: "Articles",
                    content: {
                        text: "Insert your articles’ codes, descriptions and prices once and never type them again!",
                        points: [
                            "You’ll have them suggested for you whenever you create a document.",
                            "You’ll have all the fields autocompleted for you once you select one.",
                        ],
                    },
                },
                {
                    module: "Module 3",
                    title: "Offers",
                    content: {
                        text: "Create your offers whenever you’re asked for specific pricing, and you’ll have everything ready in case they decide to order!",
                        points: [
                            "You’ll have an archive of all your offers, so you’ll see what prices worked and what didn’t, and when’s time to update them.",
                            "You’ll be able to create orders directly from existing offers, so you won’t have to insert the same data twice.",
                        ],
                    },
                },
                {
                    module: "Module 4",
                    title: "Orders",
                    content: {
                        text: "Insert your orders as soon as you receive them and their management will be super easy!",
                        points: [
                            "You’ll have a clear view of your DLs (Status of the orders), which will help you prioritize and plan your work in advance.",
                            "At delivery time, you’ll just have to select what articles you’re delivering from the orders and the DDTs will be ready in no time.",
                            "Fulfilled orders will be automatically moved to Closed orders, so you won’t be annoyed by them but still have access.",
                            "The articles you’ve just delivereed will automatically disappear from the Status of the orders, so you won’t get confused with what is to do and what is done.",
                        ],
                    },
                },
                {
                    module: "Module 5",
                    title: "Transport Documents",
                    content: {
                        text: "Start creating your DDTs whenever you’ve got something ready or just before delivery time, that’s up to you! Either case, not only it will take you no time, but you’ll save time for later too!",
                        points: [
                            "At billing time, you’ll just have to choose the customer and the DDTs you want to include, nothing more!",
                            "The DDTs you’ve just billed will automatically be moved to Closed DDTs, so you won’t be bothered by them but still have access to them.",
                            "You’ll have the possibility to create non-billable DDTs and archive them manually.",
                            "Plus, if you are a third-party company, you’ll have an option to insert the material DDTs you receive, so you’ll have an always-up-to-date view of the material you have in house (Status of the material DDTs) and the info auto-completed for you whenever you deliver it.",
                        ],
                    },
                },
                {
                    module: "Module 6",
                    title: "Invoices",
                    content: {
                        text: "Create invoices (of any type) either starting from your DDTs or from scratch.",
                        points: [
                            "Add discounts to specific items, or on top of the total price.",
                            "Change the VAT % of specific items and specify the law allowing it.",
                            "Add special items not present in your article list, like delivery costs.",
                            "Get your XML (electronic invoices) and PDF done for you.",
                        ],
                    },
                },
                {
                    module: "Module 7",
                    title: "CRM",
                    content: {
                        text: "Closely follow your customers’ habits and know when it’s time to reconnect, create a promotion or adjust your offers.",
                        points: [
                            "Be notified when too long has passed since the latest purchase, so you can take action.",
                            "Keep track of when you last contacted a customer and what was your chat about, so you don’t risk bothering him again for nothing.",
                        ],
                    },
                },
            ],
        },
        forWhom: {
            title: "For whom it is",
            subtitle: "It’s for two types of companies: the mechanic artisan and the online retailer.",
            artisan: {
                icon: "cogs",
                title: "For the mechanic artisan",
                text: [ { dockington: true }, " simplifies the management of your orders, so you can plan your production in advance and ensure punctual deliveries to your customers. It also speeds up the creation of DDTs and invoices, by making sure you spend as little time as possible with bureaucratic stuff. " ],
            },
            retailer: {
                icon: "shopping-cart",
                title: "For the online retailer",
                text: [ { dockington: true }, " speeds up the delivery process and automates the creation of the invoices. Plus, it helps you retain your existing customers by keeping track of their consumer habits, so you can formulate irresistible offers at the right time." ],
            },
        },
        collateral: {
            title: [ "Collateral effects of ", { dockington: true } ],
            subtitle: [ { dockington: true }, " can cause a series of desired effects." ],
            points: [
                "You’ll finally have more time to do your work, instead of wasting it putting your work on paper.",
                "You’ll be super clear on your DLs and never give priority again to the most annoying customer rather than according to real urgency.",
                "You’ll have no problem with carriers coming suddenly to pick up stuff, because it’ll take you no time to create the DDTs on the spot.",
                "You’ll never be vague again about your delivery times, not even when asked on the go.",
                "You’ll know your customers’ habits so well you’ll make it impossible for them not to buy from you again.",
            ],
        },
        whatYouFind: {
            title: [ "How it looks" ],
            subtitle: [ "Here are some examples of what you'll find inside." ],
        },
        facesBehind: {
            title: [ "The faces behind ", { dockington: true } ],
            messages: [
                { both: [ { h2: "Hi, nice to meet you! We are Elena and Samuli." } ] },
                { elena: [
                    "I'm an Italian from Cuneo, he's a Finnish Lappish.",
                    "We found ourselves teaming up in the robotics lab during the last year of Automation Engineering here in Helsinki, back in 2014.",
                ] },
                { samuli: [ "Since then, we've had fun building apps and software for those who get pissed staring at the same little wheel for more than 2s when clicking a button." ] },
                { elena: [ "Those who find it inconceivable to have to read a Bible-style instruction manual to understand how a program works." ] },
                { samuli: [ "Those who expect programs not only to work, but to work as expected." ] },
                { elena: [ "And those who'd want something customized, but are not willing to sell a kidney to afford it." ] },
            ],
        },
        takeAdvice: {
            title: "Take advice",
            subtitle: [ "from those who already have ", { dockington: true } ],
            reviews: [
                {
                    male: true,
                    image: {
                        src: "mauro",
                        alt: "",
                    },
                    body: [
                        "I dare anyone to find a program like this, with continuous updates and small customizations included in the price!"
                    ],
                    author: {
                        name: "Mauro",
                        title: [ "Owner of ", { outsideLink: "http://ommdimauromargaria.it", text: "O.M.M. di Mauro Margaria" } ],
                    },
                },
                {
                    image: {
                        src: "aurora",
                        alt: "",
                    },
                    body: [
                        "I was suspicious it would be a rip-off given my scarce knowledge about programs, and instead after only a few days since the beginning of the free trial I understood that I had found the best deal of my life."
                    ],
                    author: {
                        name: "Aurora",
                        title: [ "Secretary at ", { outsideLink: "http://ommdimauromargaria.it", text: "O.M.M. di Mauro Margaria" } ],
                    },
                },
                {
                    male: true,
                    image: {
                        src: "biancoline",
                        alt: "",
                    },
                    body: [
                        "Facile, veloce e sempre in aggiornamento; gli aggiornamenti sono fatti ", { quote: "ad personam" }, " e l'intero programma viene adeguato secondo le esigenze dell'azienda."
                    ],
                    author: {
                        name: "Fausto",
                        title: [ "Owner of ", { outsideLink: "https://www.biancolinenaturalfood.it", text: "Bianco-Line Pet" } ],
                    },
                },
            ],
            cta: "Did they convince you?",
        },
        littleOrMuch: {
            title: [ { b: "Does it cost little" }, " or much?" ],
            subtitle: "It depends on how much you value your time.",
            much: {
                goal: "You don’t see why you should spend money for something you can delegate to your secretary or even do yourself?",
                answer: [ "Then YES, ", { dockington: true }, " is expensive." ],
            },
            little: {
                goal: "Your goal is to spend always less time to bureaucratic work and always more to the work itself?",
                answer: [ "Then NO, ", { dockington: true }, " is really cheap." ],
            },
        },
        packages: {
            title: "Choose a ready package or create your own.",
            subtitle: "The prices do not include IVA.",
            pricePerMonth: "€ / month",
            payYearlyAndSave: "Pay yearly and save one month!",
            ready: {
                title: [ { icon: { icon: "cubes", style: { fontSize: "1.25em"}, className: "mr-1" } }, "Ready packages" ],
                packages: [
                    {
                        name: "Fiat",
                        price: "69",
                        includes: [
                            "Articles",
                            "Customers",
                            "Transport documents",
                            "Invoices (electronic)",
                        ],
                    },
                    {
                        name: "Maserati",
                        price: "99",
                        includes: [
                            "Articles",
                            "Customers",
                            { b: "Offers" },
                            { b: "Orders" },
                            "Transport documents",
                            "Invoices (electronic)",
                        ],
                    },
                    {
                        name: "Lamborghini",
                        price: "119",
                        includes: [
                            "Articles",
                            "Customers",
                            "Transport documents",
                            "Invoices (electronic)",
                            { b: "CRM" },
                        ],
                    }
                ],
            },
            single: {
                title: [ { icon: { icon: "cube", className: "mr-1" } }, "Single modules" ],
                modules: [
                    { icon: "address-book", name: "Contacts", price: "0" },
                    { icon: "tags", name: "Articles", price: "0" },
                    { icon: "comment-dollar", name: "Offers", price: "0" },
                    { icon: "folder-open", name: "Orders", price: "30" },
                    { icon: "truck", name: "DDTs", price: "30" },
                    { icon: "dollar-sign", name: "Invoices", price: "39" },
                    { icon: "bell", name: "CRM", price: "50" },
                ],
            },
        },
        faq: {
            title: "Your most frequently asked questions",
            questions: [
                {
                    q: "What if I don’t like it?",
                    a: "No probs! You can try it for free, with no commitment, for ONE MONTH! So you can take your time and decide whether or not it’s for you.",
                },
                {
                    q: "What if I have a problem?",
                    a: [
                        "That is very unlikely! But if it happens, you can send us ",
                        { href: "mailto:info@cheeksoft.com", text: "an email" },
                        "and we will get back to you with a solution within a few hours max.",
                    ],
                },
                {
                    q: "What if it’s missing a feature that I really need?",
                    a: "You should definitely let us know. We are working on new features all the time and yours could be next.",
                },
                {
                    q: "What if I don’t want to pay monthly?",
                    a: "You can choose to pay yearly, if that’s what you prefer. And the good news is, you’ll get 12 months and just pay 11!",
                },
                {
                    q: "How can I pay?",
                    a: [
                        "You can pay by Credit or Debit Card and by SEPA Direct Debit. Plus, only in the case of yearly subscription, you can choose to pay by Bank Transfer. You’d prefer to pay in some other way?",
                        { href: "mailto:info@cheeksoft.com", text: "Let us know!" },
                    ],
                },
                {
                    q: "Why do I have to pay for a subscription, and not buy it for good?",
                    a: "Great question! Here’s why: a subscription allows us to keep your data available from anywhere anytime, and gives you immediate access to future improvements without any updates nor extra costs. ",
                },
            ],
        },
        login: {
            title: [ "Login to ", { dockington: true } ],
            text: [ "Try all the features immediately ", { b: "one month for free" }, ". No commitment or credit card required." ],
            disclaimer: [ "By logging in you accept our ", { link: "/legal/terms", text: "terms of service" }, " and ", { link: "/legal/privacy", text: "privacy policy" }, "." ],
            login: "LOGIN",
            loggingIn: "LOGGING IN...",
        },
        action: {
            title: "You've wasted enough time.",
            button: "LOGIN NOW FOR FREE",
            buttonNav: "LOGIN NOW",
        },
    },
    testimonials: {
        head: {
            title: "What they say about Dockington",
            description: "Read what Dockington's users want you to know.",
        },
        tagline: {
            title: "Don't just take our word for it.",
            subtitle: [ "Take the one of those who've tried it." ],
        },
        aurora: {
            id: "aurora",
            website: "https://ommdimauromargaria.oneminutesite.it/",
            title: "Give it a try!",
            quote: [
                [ "I was suspicious it would be a rip-off given my scarce knowledge about programs, and instead after only a few days since the beginning of the free trial I understood that I had found ", { emph: "the best deal of my life." }],
                // "I recommend it to all of those who want to improve their work, while saving time!",
            ],
            author: {
                name: "Aurora",
                title: "Secretary at O.M.M. di Mauro Margaria",
                titleWithLink: [ "Secretary at ", { outsideLink: "https://ommdimauromargaria.oneminutesite.it/", text: "O.M.M. di Mauro Margaria" } ],
            },
            full: [
                [ { highlight: "Facile: " }, " essendo intuitivo, trovo subito ciò che mi serve, senza dover cliccare a destra e a manca. E in più, non c’è margine di errore!" ],
                [ { highlight: "Veloce e utile: " }, " con pochissimi click creo e gestisco i miei documenti, risparmiando un sacco di tempo. Ma non solo… dandomi la situazione degli ordini sempre aggiornata in tempo reale, mi aiuta anche a programmare la produzione e a rispettare sempre i tempi di consegna concordati con i clienti. " ],
                [ { highlight: "Affidabile: " }, " in presenza di un problemino me lo hanno risolto in tempo reale, servizio ottimo, professionale e competente ma che mantiene un rapporto familiare pur avendo le potenzialità della grande azienda. Hanno colto le mie reali esigenze migliorando il programma e rendendolo personalizzato in tempi brevissimi e senza costi aggiuntivi! " ],
                "Ultima cosa ma non di importanza: tutto questo lo posso fare da dove voglio: da casa, in ufficio… è sufficiente avere la linea internet!",
                { b: "Lo consiglio a tutti quelle che hanno voglia di migliorare il proprio lavoro e risparmiare tempo!" },
            ]
        },
        mauro: {
            id: "mauro",
            website: "https://ommdimauromargaria.oneminutesite.it/",
            title: "Give it a try!",
            quote: [
                [ "I dare anyone to find a program like this, with continuous updates and small customizations included in the price!" ],
            ],
            author: {
                name: "Mauro",
                title: "Owner of O.M.M. di Mauro Margaria",
                titleWithLink: [ "Owner of ", { outsideLink: "https://ommdimauromargaria.oneminutesite.it/", text: "O.M.M. di Mauro Margaria" } ],
            },
            full: [
                [ "Dockington è ", { b: "facile da usare" }, " ma allo stesso tempo ", { b: "estremamente intelligente" }, ". Mi permette in pochi click di creare le offerte, le bolle e le fatture, e di avere sempre la situazione aggiornata degli ordini e di conseguenza programmare la produzione. In pratica, grazie a Dockington ", { b: "posso migliorare ogni giorno i servizi ai miei clienti" }, "." ],
                [ "In più, l’", { b: "aggiornamento" }, " è ", { b: "costante" }, " e l’", { b: "assistenza in tempo reale" }, " mi dà la possibilità di personalizzare il programma." ],
                [ "Prezzo che rappresenta un altro punto forte, visto che non richiede ", { b: "alcun grande investimento iniziale" }, "! Infatti, pagando solo quando lo usi, puoi cessare l’abbonamento quando vuoi senza buttare via soldi inutilmente." ],
                { b: "Lo consiglio a tutti quelli che come me non sono così esperti di programmi e che vogliono ottimizzare il lavoro. E anche a chi è spesso fuori sede." }
            ]
        },
        biancoline: {
            id: "biancoline",
            website: "https://www.biancolinenaturalfood.it/",
            title: "EXCELLENT PROGRAM.",
            quote: [
                [ "Facile, veloce e sempre in aggiornamento; gli aggiornamenti sono fatti \"ad personam\" e l'intero programma viene adeguato secondo le esigenze dell'azienda."],
            ],
            author: {
                name: "Fausto",
                title: "Owner of Bianco-Line Pet",
                titleWithLink: [ "Owner of ", { outsideLink: "https://www.biancolinenaturalfood.it/", text: "Bianco-Line Pet" } ],
            },
            full: [
                "Con l'avvento della fatturazione elettronica a partire dal Gennaio 2019 eravamo alla ricerca di un programma semplice, veloce e funzionale.",
                "Siamo venuti a conoscenza di Dockington tramite e-mail e siamo stati subito catturati dalla semplicità della spiegazione di Elena e dall'ottima tariffa offerta. Abbiamo iniziato il periodo di prova ma da subito siamo stati entusiati del programma.",
                "L'assistenza è rapidissima ed Elena e il suo team sempre molto gentili.",
                "Siamo un realtà artigiana ed avevamo bisogno di un programma semplice e di base. Grazie a loro abbiamo trovato la giusta soluzione.",
                "Complimenti ancora per il vostro lavoro.",
                "Una realtà giovane e intraprendente. PROGRAMMA ECCELLENTE."
            ]
        },
    },
    about: {
        head: {
            title: "About us",
            description: "Get to know who's behind Dockington",
        },
        tagline: {
            title: "Hey, nice to meet you!",
            subtitle: "We're Elena and Samuli, and as a job we simplify the life of small businesses.",
        },
        imgAlt: "Samuli Peurasaari and Elena Margaria",
        elena: {
            name: "Elena Margaria",
            title: "Co-founder, CEO, Dev",
        },
        samuli: {
            name: "Samuli Peurasaari",
            title: "Co-founder, CTO, Dev",
        },
        messages: [
            { elena: [
                "I'm an Italian from Cuneo, he's a Finnish Lappish.",
                "We found ourselves teaming up in the robotics lab during the last year of Automation Engineering here in Helsinki, back in 2014.",
            ] },
            { samuli: [ "Since then, we've had fun building apps and software for those who get pissed staring at the same little wheel for more than 2s when clicking a button." ] },
            { elena: [ "Those who find it inconceivable to have to read a Bible-style instruction manual to understand how a program works." ] },
            { samuli: [ "Those who expect programs not only to work, but to work as expected." ] },
            { elena: [ "And those who'd want something customized, but are not willing to sell a kidney to afford it." ] },
            { both: [ { h2: "Why are you here?" } ], className: "mt-3" },
            { elena: [ "Let me guess... If I know you at least a bit, because you're one of them." ] },
            { samuli: [ "You're tired of taking the usual rip-offs, duped by those who speak speak and then ask you a disproportionate amount for a change that actually takes an hour." ] },
            { elena: [
                "Or you are tired of those standard programs that you find on the internet, that when they don't work and you need help, you can't even talk to someone who can solve the problem.",
                "You can forget about having it solved right away!",
            ] },
            { both: [ { h2: "Instead we like listening." } ], className: "mt-3" },
            { samuli: [ "Before, during and after the execution of a project." ] },
            { elena: [ "Before, because we want to meet your needs, not ours or those of someone else." ] },
            { samuli: [ "During, because it's just with constant feedback that we can realize your ideas." ] },
            { elena: [ "After, because we want to make sure everything is as you imagined: perfect!" ] },
            { samuli: [ { h2: "Who do we work with, you might ask?" } ], className: "mt-3" },
            { elena: [ "With micro, small and medium companies that want to simplify the management of their business, automating repetitive operations, from the creation of documents to that of graphs in Excel." ] },
            { samuli: [ "With e-commerce companies, which fulfill one order after the other and cannot afford to waste time creating DDTs." ] },
            { elena: [ "With third-party companies, which need to have clear priorities and cannot afford to exceed the delivery times promised to customers." ] },
            { both: [ { h2: "Together, forever!" } ], className: "mt-3" },
            { samuli: [ "When we deliver a finished project, the relationship with the company doesn't end there. It begins." ] },
            { elena: [ "With some it even happens we call and discuss weekly. Because when you work together, towards a common goal, from an idea comes another one and then another." ] },
            { samuli: [ { h2: "Not sure where to start?" } ], className: "mt-3" },
            { elena: [
                [ "If you're pissed off of wasting time in the office between orders, DDTs and invoices take a look at ", { link: "/", text: { dockington: true } }, ", the management software tailored for you." ],
                "Don't be fooled: those are just the basic features to start from, the rest we do it just for you!"
            ] },
            { samuli: [
                [
                    "If instead you are looking for a program to keep an eye on your finances, without having to enter data over data in Excel for some graphs that give you a just a vague idea, take a look at ",
                    { link: "/dockington-reports", text: { dreports: true } }, ", the software that makes the statistics for you."
                ]
            ] },
            { both: [ { h2: "We hope we'll hear from you soon!" } ], className: "mt-3" },
        ],
        signature: "Elena and Samuli",
        company: {
            title: "Our company",
            subtitle: "Whenever you need us, we're here to help.",
            map: "Helsinki, Finland",
        }
    },
    signup: {
        head: {
            title: "Dockington Test Drive - Sign up",
            description: "Sign up for a free month Dockington Test Drive!",
        },
        tagline: {
            // title: "Need more time to get things done?",
            title: "Ready to achieve more in less time?",
            subtitle: [ "Say goodbye to repetitive tasks now." ],
        },
        form: {
            title: [ "Try ", { dockington: true }, " for free, for 1 month, with no commitment." ],
            name: {
                label: "Name",
                placeholder: "Your name",
            },
            company: {
                label: "Company",
                placeholder: "Your company's name",
                comment: "Your company login name will be",
            },
            email: {
                label: "Email",
                placeholder: "Your email address",
            },
            yes: "YES, I'M READY!",
            no: "No, thanks. I like wasting time.",
            error: {
                connection: "There was a problem connecting to Dockington. Please check your internet connection and try again in a few minutes.",
                nameInUse: "The given email and/or company login name is already in use. Please try with another email or company name.",
                internal: "There was a problem connecting to Dockington. Please wait a few minutes and try again.",
            },
            consent: [
                "By signing up you agree to our ", { href: "/legal/terms", text: "Terms and Conditions"}, ". We'll send you emails about our products and services, but you can opt-out at any time."
            ],
        },
        details: {
            title: [ "Here’s what you can and cannot do with ", { dockington: "Dockington Test Drive" }, ":" ],
            points: [
                { can: true, point: "Save all your customers’ info and products: type them once, and you’ll have them autofilled for you whenever you need." },
                { can: true, point: "Create offers, DDTs and invoices: just a few clicks, and you’ll have your PDFs ready to send or print." },
                { can: true, point: "Create your electronic invoices without even noticing it: once you have your invoices, the XML document is just one click away. You choose whether to download the PDF, the XML or both." },
                { can: true, point: "Register incoming orders and keep track of their current status: a quick glance, and you’ll know exactly what needs to be done next." },
                { can: false, point: "Customize PDFs with your company’s logo." },
            ],
        }
    },
    thankyou: {
        head: {
            title: "Thank you!",  // TODO: !!!
            description: "",  // TODO: !!!
        },
        tagline: {
            title: "YOU’RE THE BEST!",
            subtitle: [ "Thanks so much for giving Dockington a try. It won’t disappoint you, we promise!" ],
        },
        card: {
            title: "We’re truly honoured you believe it can change your life for the better.",
            advice: [
                "A welcome email is on its way from us to your inbox. Be sure to read it -- ",
                { b: "it’s got the link to access " }, { dockington: true }, { b: " right now." },
            ],
            greetings: "With great appreciation,",
            signature: "Elena and Samuli",
            ps: "P.S. If you don’t get any email from us in your inbox, remember to check your spam folder!",
        },
        car: "Thank you car",
    },
    notFound: {
        head: {
            title: "Page not found",
            description: "",
        },
        title: "404 - Page not found",
        text: [
            "We're sorry to say the page you were looking for couldn't be found.",
            [ "If you think this is a mistake, please ", { text: "let us know", outsideLink: "mailto:info@cheeksoft.com" }, "!" ],
            [ { text: "Click here", link: "/" }, " to return to the front page." ],
        ],
    },
    footer: {
        privacyAndTerms: "Privacy and Terms",
    },
    legal: {
        privacy: {
            head: {
                title: "Privacy Policy",
                description: "",
            },
            title: "Privacy Policy",
            subtitle: "Last update: 24 March 2019",
            sections: [
                { id: "overview",        name: "1. Overview" },
                { id: "what-we-collect", name: "2. Personal data we collect" },
                { id: "how-we-use",      name: "3. How we use Personal Data" },
                { id: "how-we-disclose", name: "4. How we disclose Personal Data" },
                { id: "your-rights",     name: "5. Your rights and choices" },
                { id: "security",        name: "6. Security and retention" },
                { id: "updates",         name: "7. Updates to this Privacy Policy and notifications" },
                { id: "contact",         name: "8. Contact" },
            ],
        },
        cookies: {
            head: {
                title: "Cookie Policy",
                description: "",
            },
            title: "Cookie Policy",
            subtitle: "Last update: 24 March 2019",
            sections: [
                { id: "how-we-use",            name: "1. How we use cookies" },
                { id: "other-technologies",    name: "2. How we use other technologies" },
                { id: "how-to-manage-cookies", name: "3. How to manage cookies" },
            ],
        },
        terms: {
            head: {
                title: "Terms of Service",
                description: "",
            },
            title: "Terms of Service",
            subtitle: "Last update: 24 March 2019",
            sections: [
                { id: "communications",        name: "1. Communications" },
                { id: "subscriptions",         name: "2. Subscriptions" },
                { id: "free-trial",            name: "3. Free trial" },
                { id: "fee-changes",           name: "4. Fee changes" },
                { id: "refunds",               name: "5. Refunds" },
                { id: "exporting-your-data",   name: "6. Exporting your data" },
                { id: "content",               name: "7. Content" },
                { id: "accounts",              name: "8. Accounts" },
                { id: "intellectual-property", name: "9. Intellectual Property" },
                { id: "links",                 name: "10. Links to other websites" },
                { id: "termination",           name: "11. Termination" },
                { id: "indemnification",       name: "12. Indemnification" },
                { id: "liability",             name: "13. Limitation Of Liability" },
                { id: "changes",               name: "14. Changes" },
            ],
        },
    },
    cookiepopup: {
        text: [ "We use cookies to provide you with the best possible experience. See our ", { href: "/legal/cookies", text: "Cookie Policy" }, " for details." ],
        accept: "I accept",
    },
    blogs: {
        head: {
            title: "Blog - Dockington",
            description: "Our tips and tricks for making your worklife more enjoyable.",
        },
        tagline: {
            title: "Work smarter, not harder",
            subtitle: "Our tips and tricks for making your worklife more enjoyable.",
        },
        subscribe: {
            miniguide: {
                title: "Why make the company's stats yourself, when you can have them done for you for free?",
                text: [
                    "Become an Insider and get right away your free Dockington Reports, the program that does them for you! (Sounds too good to be true? ", { link: "/dockington-reports", text: "See for yourself!" }, ")", { br: true },
                    "You'll learn 3 different angles to look at your statistics from, and the questions to ask to get the most out of them.",
                ],
                disclaimer: [
                    "As an Insider, you'll also get insights on techniques and tools to improve your business.", { br: true },
                    "(No spam, we hate it as much as you do.)",
                ],
                subscribe: "I WANT IT!",
                subscribing: "SENDING...",
            },
            title: "Become a",
            text: "You'll get insights on techniques and tools to improve your business.",
            name: "NAME",
            email: "EMAIL",
            subscribe: "SUBSCRIBE",
            subscribing: "SUBSCRIBING...",
            miniguideToNumbers: "Miniguide to your numbers",
            thankyou: {
                imageAlt: "Thanks so much for becoming a Dockington Insider",
                title: "YOU'RE THE BEST!",
                text: [
                    "Thanks so much for becoming a Dockington Insider! We’re super excited!!",
                    [ "A welcome email is on its way from us to your inbox, together with the link to download your ", { dreports: true }, "." ],
                ],
                signature: {
                    greeting: "With appreciation,",
                    sign: "Elena and Samuli",
                },
                ps: "P.S. If you don’t get any email from us in your inbox, remember to check your spam folder!",
            },
            error: {
                nameRequired: "Please introduce yourself :)",
                emailRequired: "And email address is required to receive email updates.",
                emailInvalid: "The given email address is not valid, please check that it is correct.",
                connection: "There was a problem connecting to Dockington. Please check your internet connection and try again in a few minutes.",
                internal: "There was a problem connecting to Dockington. Please wait a few minutes and try again.",
            },
        },
        readmore: "Read more",
        previous: "Previous",
        next: "Next",
        share: "If you like this post, give us a clap (or two, why not!) and share it on Facebook. You never know who may need it :)"
    },
    reports: {
        head: {
            title: "Dockington Reports",
            description: "Get your graphs without lifting a finger and identify the most precious customers, spot the unnecessary expenses and keep an eye on the trend of costs and revenues.",
            keywords: [
                "cost and revenue reports",
                "customer and supplier reports",
                "profit margin reports",
                "electronic invoice report",
                "revenue and profit graphs",
                "customer and supplier graphs"
            ],
        },
        title: [ "Why make the company's stats yourself,", { block: "when you can have them done for you?" } ],
        understanding: {
            title: [ { dreports: true } ],
            subtitle: "The program that turns your invoice XMLs directly into graphs, allowing you to have your stats always ready, without having to spend a single minute doing them.",
            text: [
                // [ "You won’t have to spend any time on your stats, but have them anyway." ],
            ],
        },
        solution: {
            includesTitle: "What you get",
            includes: [
                [ { b: "Super-easy setup in less than 10s" }, ", so you can get started right away." ],
                [ { b: "Automatic data reading from your XML invoices" }, ", so you don't need to worry about inserting them manually." ],
                [ { b: "Automatic data update" }, ", so your graphs always reflect the reality." ],
                [ { b: "2 different views for costs / revenues and customers / suppliers" }, ", so you don't get overwhelmed by too much info, but know exactly where to find what you're looking for." ],
                [ { b: "Filters by month and year" }, ", so you can focus on the period you're interested in and ignore the rest." ],
                [ { b: "Multi-selection of customers and suppliers" }, ", so you can see them side by side without losing the thread." ],
                [ { b: "Well-thought easy-to-read charts for different goals" }, ", so you can instantly spot what's working and what needs to be improved." ],
                [ { b: "Clear legends and helpful tooltips" }, ", so your decisions are based on real numbers and not inaccurate estimates." ],
            ],
            title: "What you can do",
            setup: {
                title: "Keep an eye on your sales and purchases in real-time.",
                text: "You'll see right away if something's not going as it should. Which means you'll be able to change direction before it's too late. ",
                imgAlt: "Costs and revenues statistics with Dockington Reports",
            },
            visualize: {
                title: "Visualize where your money comes from...",
                text: "You'll spot immediately your precious customers, and get crystal clear on your priorities.",
                imgAlt: "Customers statistics with Dockington Reports",
            },
            know: {
                title: "... and where it goes to.",
                text: "You'll easily distinguish between necessary costs, investments and wastes. So you'll always feel confident you're spending your money for the right things.",
                imgAlt: "Suppliers statistics with Dockington Reports",
            },
        },
        proof: {
            aurora: {
                // title: "Give it a try!",
                quote: [
                    "OMG! I still cannot believe it: finally no more data to insert in Excel. Dockington Reports is magic!",
                    // "I recommend it to all of those who want to improve their work, while saving time!",
                ],
                author: {
                    name: "Aurora",
                    title: "Secretary at O.M.M. di Mauro Margaria",
                },
            },
        },
        trust: {
            title: "Is this really for you?",
            subtitle: "Here are a few red flags that might mean it's not:",
            points: [
                { reason: "You already have a software that visualizes your numbers in the form of graphs.", comment: "If you're happy with it, go ahead with that: you don't need another one. Unless you want to throw your money." },
                { reason: "You want to replace your secretary or accountant.", comment: "There's no way you can replace expert advice with a software, and Dockington Reports is no exception. It does give you an overview of your numbers in real-time, but drawing the conclusions is still a human's job." },
            ],
            objections: [
                {
                    title: "Everything is on your computer: no connection required",
                    text: [
                        [ { dreports: true }, " is a program that runs on your computer so you don't need to upload your invoice XMLs anywhere." ],
                        "In this way, not only you have full control of where you keep them, but you also save the time you'd spend uploading them on the cloud.",
                    ],
                    imgAlt: "Your statistics without an internet connection",
                },
                {
                    title: "You deserve to make the most out of your time",
                    text: [
                        "If you have been manually inserting your data for years, welcome to the club! There was no other way than doing it yourself, so of course you did.",
                        [ "But now the choice is yours: you can keep spending hours adding numbers to Excel or you can let ", { dreports: true }, " do it for you while you do something more valuable." ],
                    ],
                    imgAlt: "Use your time doing something valuable instead of creating statistics",
                },
                {
                    title: "Your secretary will be happy too",
                    text: [
                        "Not you the one inserting the data? Then you have one more compelling reason.",
                        "Your secretary will be happy to finally use her expertise instead of doing repetitive manual work that anybody (even a program) can do.",
                    ],
                    imgAlt: "Your secretary will be happy to not have to manually create statistics",
                },
            ],
        },
        faq: {
            title: "FAQ",
            points: [
                // { q: "Do I need an internet connection to use it?", a: "Nope! Once you've installed it on your computer, you can use it at any time. Even with no internet connection." },
                { q: "Does it work on any computer?", a: "Yep, and with any operating system. Once you get the download link, you'll be able to choose the version you need: Windows, Linux or macOS." },
                // { q: "How much does it cost?", a: "30€ (as a night in pizzeria!), or 24€ if you buy it as Dockington Insider, VAT 24% included." },
                // { q: "How can I pay?", a: "With Visa, Mastercard or American Express." },
                // { q: "Can I get an invoice?", a: "Yes! After your purchase, respond to the confirmation email with your company's information, and we'll send you an invoice within 7 days." },
                // { q: "Do you offer refunds?", a: "No, all sales are final." },
                { q: "Is it really free?", a: "Yes, once you download it, it's free for you forever. Get it now while the offer lasts!" }
            ],
        },
        reasons: {
            title: "Use this whole electronic invoicing thing to your advantage and...",
            points: [
                "Visualize your costs' and revenues' trends",
                "Be aware of your month-by-month profit",
                "Compare your years' numbers",
                "Identify your most valuable customers",
                "Spot the sources of your highest expenses",
            ],
            questions: [
                "... All without even lifting a finger.",
                "... For the price of just one pizza-night.",
                "... With no internet connection required.",
            ],
            text: "All you need is your xml files stored on your computer!",
        },
        signup: {
            button: "GET IT NOW FOR FREE",
            form: {
                title: [ "DOWNLOAD IT NOW FOR FREE" ],
                text: [ "Sign up to our free newsletter and get the link to download it immediately. You'll become a ", { insider: true }, " and as such you'll receive our tips on tools and strategies to improve your business." ],
                subtitle: "",
                disclaimer: "(No spam, we hate it as much as you do.)",
                subscribe: "I WANT IT!",
                subscribing: "SENDING...",
            },
        },
        form: {
            title: [
                "Buy ", { dockington: true }, " Reports now for",
            ],
            offer: "BUY NOW",
            price: {
                offer: [ "INSIDER", "SPECIAL OFFER" ],
                preorderOffer: [ "PREORDER", "SPECIAL OFFER" ],
                expires: "Expires in",
                original: "45.00 €",
                discounted: "36.00 €",
                preorderOriginal: "30.00 €",
                preorder: "15.00 €",
                expired: "SOLD OUT",
            },
            emailDownloadLink: "You will receive your download link to this email",
            noCreditCard: "(No credit card required.)",
            disclaimer: {
                highlight: [
                    { b: "Become a " }, { dockington: true }, { b: " Insider and receive an immediate 20% discount!" },
                ],
                text: [
                    "You'll also get insights on techniques and tools to improve your business.",
                ],
            },
            thankyou: {
                imageAlt: "Thanks so much for choosing Dockington Reports",
                title: "HIGH FIVE!",
                text: [
                    [ "WOOO, well done!! Thanks so much for choosing ", { dockington: true }, " Reports! We’re super excited that there will be no more secrets between you and your numbers!" ],
                    [ "A confirmation email and the receipt of your payment are on their way to your inbox. Be sure to read them -- ", { b: "they've got important info." } ],
                ],
                signature: {
                    greeting: "With appreciation,",
                    sign: "Elena and Samuli",
                },
                ps: "P.S. If you don’t get any email from us in your inbox, remember to check your spam folder!",
                visit: {
                    title: "Visit our:",
                    home: "Website",
                    blog: "Blog",
                },
            },
            thankyouInsider: {
                imageAlt: "Thanks so much for becoming a Dockington Insider",
                title: "HIGH FIVE!",
                text: [
                    [ "WOOO, well done!! Thanks so much for becoming a ", { dockington: true }, " Insider and choosing ", { dockington: true }, " Reports. We’re super excited that there will be no more secrets between you and your numbers!" ],
                    [ "A confirmation email and the receipt of your payment are on their way to your inbox. Be sure to read them -- ", { b: "they've got important info." } ],
                ],
                signature: {
                    greeting: "With appreciation,",
                    sign: "Elena and Samuli",
                },
                ps: "P.S. If you don’t get any email from us in your inbox, remember to check your spam folder!",
                visit: {
                    title: "Visit our:",
                    home: "Website",
                    blog: "Blog",
                },
            },
        },
        buy: {
            full: "Buy without signing up",
            preorder: "Buy now with 50% discount",
        },
        download: {
            head: {
                title: "Download Dockington Reports",
                description: "Download your installer for Dockington Reports",
            },
            title: [ "Download ", { dockington: true }, " Reports" ],
            selectPlatform: "Select your platform",
            linux: {
                title: "Linux",
                body: "Compatible with Ubuntu 12.04, Fedora 21 and Debian 8, and newer",
                install: [
                    "Download the .AppImage executable by clicking on the Linux button above.",
                    [ "Follow ", { outsideLink: "https://docs.appimage.org/user-guide/run-appimages.html#download-make-executable-run", text: "these instructions" }, " on how to run an AppImage application." ],
                ],
            },
            windows: {
                title: "Windows",
                body: "Compatible with 32 and 64-bit Windows 7, 8 and 10",
                install: [
                    "Download the setup .exe by clicking on the button above.",
                    "Double click on the downloaded .exe file and follow the installation instructions.",
                ],
            },
            mac: {
                title: "macOS",
                body: "Compatible with macOS 10.10 Yosemite and newer",
                install: [
                    "Download the .dmg file by clicking on the button above.",
                    "Open the .dmg file with a double click.",
                    [ "Drag and drop the ", { dockington: true }, " Reports icon to the Applications folder." ],
                ],
            },
            installationTitle: "Installation instructions",
            help: [
                "If you're having any issues on installing or running ", { dockington: true }, " Reports, please do not hesitate to contact us at ", { outsideLink: "mailto:dockington@cheeksoft.com", text: "dockington@cheeksoft.com" }, ".",
            ],
            loading: "Loading...",
        },
    },
    common: {
        daysAbbreviation: "D",
        button: {
            close: "Close",
        },
        shareOnFacebook: "Share on Facebook",
        forms: {
            company: "Company",
            name: "Name",
            email: "Email",
            subscribe: "SUBSCRIBE",
            subscribing: "SUBSCRIBING...",
            placeholder: {
                name: "Your name",
                company: "Your company's name",
                email: "Your email address",
            },
            error: {
                nameRequired: "Please introduce yourself :)",
                emailRequired: "And email address is required to receive email updates.",
                companyRequired: "Please give your company's name containing at least 3 letters.",
                emailInvalid: "The given email address is not valid, please check that it is correct.",
                connection: "There was a problem connecting to Dockington. Please check your internet connection and try again in a few minutes.",
                internal: "There was a problem connecting to Dockington. Please wait a few minutes and try again.",
            },
        },
    },
};

export default english;
