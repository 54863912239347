import React from "react"
import { library } from "@fortawesome/fontawesome-svg-core"
import {
    faCaretDown, faCaretUp, faCheck, faCheckCircle, faTimes, faTimesCircle, faFutbol, faChartBar,
    faUserClock, faExclamationTriangle, faQuestion, faUnlockAlt, faDollarSign, faPowerOff, faCouch,
    faPaperclip, faArrowLeft, faArrowRight, faChartLine, faChartPie, faChartArea, faSearchDollar, faBed,
    faBusinessTime, faFileInvoiceDollar, faCloudUploadAlt, faHandPointRight, faBinoculars, faFolderOpen,
    faUsers, faCalendarCheck, faHandshake, faAddressBook, faTags, faCommentDollar, faTruck, faBell,
    faPhone, faSms, faEnvelope, faFileAlt, faCogs, faShoppingCart, faCube, faCubes, faBars
} from "@fortawesome/free-solid-svg-icons";
import { faWindows, faLinux, faApple } from "@fortawesome/free-brands-svg-icons";
import Modal from "react-modal";

import "../assets/css/bootstrap.css";
import "../assets/css/index.css";

import Footer from "./Footer";
import CookiePopup from "./CookiePopup";
import TranslateContext from "../translations/context";
import { DEFAULT_LOCALE } from "../translations";

library.add(
    faCaretDown, faCaretUp, faCheck, faCheckCircle, faTimes, faTimesCircle, faFutbol, faChartBar,
    faUserClock, faExclamationTriangle, faQuestion, faUnlockAlt, faDollarSign, faPowerOff, faCouch,
    faPaperclip, faArrowLeft, faArrowRight, faChartLine, faChartPie, faChartArea, faSearchDollar, faBed,
    faBusinessTime, faFileInvoiceDollar, faCloudUploadAlt, faHandPointRight, faBinoculars, faFolderOpen,
    faUsers, faCalendarCheck, faHandshake, faAddressBook, faTags, faCommentDollar, faTruck, faBell,
    faPhone, faSms, faEnvelope, faFileAlt, faCogs, faShoppingCart, faCube, faCubes, faBars
    // brands
    , faWindows, faLinux, faApple
);

if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]');
}

Modal.setAppElement("#___gatsby");

const Layout = function ({ location, locale, children }) {
    return (
        <TranslateContext.Provider value={locale ? locale : DEFAULT_LOCALE}>
            <div className="body">
                {children}
            </div>
            <Footer location={location} />
            <CookiePopup />
        </TranslateContext.Provider>
    );
}

export default Layout;
