import React, { CSSProperties } from 'react';

export interface RowProps {
    justify?: "left" | "center" | "right" | "around" | "between",
    align?: "top" | "center" | "bottom",
    noWrap?: boolean,
    className?: string,
    style?: CSSProperties,
}

const Row: React.FunctionComponent<RowProps> = function (props) {
    let style = props.style;
    if (props.noWrap) {
        style = Object.assign({ flexWrap: "nowrap" }, props.style);
    }
    let className = "row";
    if (props.className) {
        className += " " + props.className;
    }
    if (props.justify) {
        switch (props.justify) {
            case "left": className += " justify-content-start"; break;
            case "center": className += " justify-content-center"; break;
            case "right": className += " justify-content-end"; break;
            case "around": className += " justify-content-around"; break;
            case "between": className += " justify-content-between"; break;
        }
    }
    if (props.align) {
        switch (props.align) {
            case "top": className += " align-items-start"; break;
            case "center": className += " align-items-center"; break;
            case "bottom": className += " align-items-end"; break;
        }
    }
    return (
        <div className={className} style={style}>
            {props.children}
        </div>
    )
}

export default Row;
