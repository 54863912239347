import React from "react";

import Link from "./common/Link";
import withTranslate from "./translate";
import LanguageSelect from "./common/LanguageSelect";

const Footer = withTranslate(function ({ location, translate }) {
    return (
        <footer className="footer">
            <p>&copy; {new Date().getFullYear()} CheekSoft Oy</p>
            <p className="privacyterms">
                <Link to="/legal/privacy">{translate("footer.privacyAndTerms")}</Link>
            </p>
            <LanguageSelect location={location} />
        </footer>
    )
});

export default Footer;
