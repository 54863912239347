import React from "react";

import { getTranslate, DEFAULT_LOCALE } from "../translations";
import TranslateContext from "../translations/context";

export const withTranslate = function (WrappedComponent) {
    return (props) => (
        <TranslateContext.Consumer>
            {(locale) => (
                <WrappedComponent
                    {...props}
                    locale={locale}
                    localePath={locale === DEFAULT_LOCALE ? "" : `/${locale}`}
                    translate={getTranslate(locale)}
                />
            )}
        </TranslateContext.Consumer>
    );
}

export default withTranslate;
