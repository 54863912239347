
import english from "./en";
import italian from "./it";

export const DEFAULT_LOCALE = "it";

let currentLanguage: "en" | "it" = DEFAULT_LOCALE;
const translations = {
    en: english,
    it: italian,
}

const deepGet = function (map: any, path: string, defaultValue?: any) {
    let val = path.split(".").reduce((parent, name) => (parent && parent[name]), map);
    return typeof val !== "undefined" ? val : defaultValue;
}

const translate = function (key: string) {
    return deepGet(translations[currentLanguage], key, "Missing translation in " + currentLanguage + " for key " + key);
}

export const getTranslate = function (currentLanguage: "en" | "it" = "en") {
    return function (key: string) {
        return deepGet(translations[currentLanguage], key, "Missing translation in " + currentLanguage + " for key " + key);
    };
}

export const setLanguage = function (code: "en" | "it") {
    currentLanguage = code;
}

export const getLanguage = function () {
    return currentLanguage;
}

export default translate;
