import React from "react";
import { Link } from "gatsby";

import it from "../../assets/images/it.svg";
import en from "../../assets/images/gb.svg";
import Row from "./Row";

import "./LanguageSelect.css";

const LanguageSelect = function ({ location }) {
    let currentPath = location.pathname;
    if (currentPath.startsWith("/en/")) {
        currentPath = currentPath.substr(3);
    }
    const locales = [
        { flag: it, locale: "IT", alt: "Italiano", link: currentPath },
        { flag: en, locale: "EN", alt: "English", link: "/en" + (currentPath === "/" ? "" : currentPath) },
    ];
    return (
        <Row justify="center">
            <div className="language-select">
                <div className="lang-list">
                    {locales.map((locale) => (
                        <div className="lang-item">
                            <Link to={locale.link}>
                                <div className="lang-item-content">
                                    <img src={locale.flag} alt={locale.alt} />
                                    <span className="lang">{locale.locale}</span>
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </Row>
    );
}

export default LanguageSelect;
