import React, { Component } from "react";
import Cookies from 'universal-cookie';

import Link from "./common/Link";
import Button from "./common/Button";
import withTranslate from "./translate";

import "./CookiePopup.css";

class CookiePopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hide: true,
        };
    }
    componentDidMount() {
        const cookies = new Cookies();
        let accept = cookies.get("cookie_accept");
        if (accept !== "ok") {
            this.setState({ hide: false });
        }
    }
    render() {
        if (this.state.hide) {
            return null;
        }
        return (
            <div className="cookie-popup-container">
                <div className="cookie-popup">
                    <div className="cookie-text">{this.props.translate("cookiepopup.text").map((part, i) => (part.href ? <Link key={i} to={part.href}>{part.text}</Link> : part))}</div>
                    <div>
                        <Button className="cookie-btn" onClick={() => {
                            if (window.gtag) {
                                window.gtag('event', "cookies-accept", {
                                    'event_category' : 'cookies',
                                    'event_label' : "Accept cookies on popup"
                                });
                            }
                            const cookies = new Cookies();
                            cookies.set("cookie_accept", "ok", {
                                path: "/",
                                maxAge: 60 * 60 * 24 * 365 * 10, // 10 years
                                domain: ".dockington.com",
                            });
                            this.setState({ hide: true });
                        }}>
                            {this.props.translate("cookiepopup.accept")}
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslate(({ translate }) => <CookiePopup translate={translate} />);
