import React from "react"
import Helmet from "react-helmet"

import withTranslate from "./translate";

const SEO = function ({ localePath, locale, description, keywords, title, path, meta, children }) {
    keywords = keywords || [];
    meta = meta || [];

    if (!title) {
        title = "Dockington";
    }
    const lang = locale;
    const metaDescription = description || "Con Dockington gestisci gli ordini, programmi la produzione e fatturi online, sempre e ovunque.";

    const locales = [
        { locale: "it", localePath: "" },
        { locale: "en", localePath: "/en" }
    ];

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            meta={[
                {
                    name: "description",
                    content: metaDescription,
                },
                {
                    property: "og:title",
                    content: title,
                },
                {
                    property: "og:description",
                    content: metaDescription,
                },
                {
                    property: "og:type",
                    content: "website",
                },
                {
                    name: "twitter:card",
                    content: "summary",
                },
                {
                    name: "twitter:creator",
                    content: "CheekSoft Oy",
                },
                {
                    name: "twitter:title",
                    content: title,
                },
                {
                    name: "twitter:description",
                    content: metaDescription,
                },
            ]
                .concat(
                    keywords.length > 0
                        ? {
                            name: "keywords",
                            content: keywords.join(", "),
                        }
                        : []
                )
                .concat(meta)}
        >
            {path && <link rel="canonical" href={"https://www.dockington.com" + localePath + path} />}
            {path && locales.map((loc) => (
                <link rel="alternate" hreflang={loc.locale} href={`https://www.dockington.com${loc.localePath}${path}`} />
            ))}
            <script type="application/ld+json">
{`{
    "@context": "https://schema.org",
    "@type": "Organization",
    "legalName": "CheekSoft Oy",
    "brand": {
        "@type": "Brand",
        "name": "Dockington",
        "logo": {
        	"@type": "ImageObject",
        	"url": "https://www.dockington.com/images/logo.png"
        }
    },
    "url": "https://www.dockington.com",
    "logo": {
        "@type": "ImageObject",
        "url": "https://www.dockington.com/images/logo.png"
    },
    "contactPoint": [
        {
            "@type": "ContactPoint",
            "email": "info@cheeksoft.com",
            "contactType": "customer service",
            "url": "https://www.dockington.com/about",
            "availableLanguage": [
                "Italian",
                "English"
            ]
        }
    ]
}`}
            </script>
            <script type="application/ld+json">
{`{
	"@context": "http://schema.org",
	"@type": "WebApplication",
	"name": "Dockington",
	"description": "Con Dockington gestisci gli ordini, programmi la produzione e fatturi online, sempre e ovunque.",
	"applicationCategory": "http://schema.org/BusinessApplication",
	"publisher": "CheekSoft Oy",
	"url": "https://www.dockington.com/signup",
	"operatingSystem": [
	    "Windows 7",
	    "Windows 8",
	    "Windows 8.1",
	    "Windows 10",
	    "Linux",
	    "OSX",
	    "macOS"
	]
}`}
            </script>
            {children}
        </Helmet>
    )
}

export default withTranslate(SEO);
