import React from "react";
import { Link as GatsbyLink } from "gatsby";
import withTranslate from "../translate";

const Link = withTranslate(function (props) {
    return (
        <GatsbyLink className={props.className} style={props.style} onClick={props.onClick} to={(props.localePath || "") + props.to}>
            {props.children}
        </GatsbyLink>
    );
});

export default Link;
