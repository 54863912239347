
const italian = {
    nav: {
        product: { dockington: true },
        pricing: "Prezzi",
        faqs: "Aiuto",
        reports: { dreports: true },
        testimonials: "Recensioni",
        about: "Chi siamo",
        blog: "Blog",
        signup: "Registrati",
        software: "Il gestionale",
        forWhom: "Per chi è",
        why: "Perché",
        whoAreWe: "Chi siamo?",
        pricing: "Prezzi"
    },
    hellobar: {
        text: [ "Scarica GRATIS ", { dreports: true }, ", il programma che fa le statistiche aziendali al posto tuo." ],
        action: "LO VOGLIO!",
    },
    main: {
        head: {
            title: "Il gestionale per l'officina meccanica di precisione - Dockington",
            description: "Il software gestionale pensato apposta per le officine meccaniche di precisione, che semplifica la gestione degli ordini (sia di vendita che conto lavoro) e la creazione di DDT e fatture elettroniche.",
            keywords: [
                "gestionale officina meccanica di precisione",
                "software gestionale officina meccanica di precisione",
                "gestionale per aziende di produzione conto terzi",
                "software gestionale per aziende di produzione conto terzi",
                "gestionale per aziende di lavorazione meccanica",
                "software gestionale per aziende di lavorazione meccanica",
                "gestionale officina meccanica",
                "software gestionale officina meccanica",
            ],
        },
        grab: {
            tagline: {
                title: "Il gestionale per chi",
                subtitle: [ "non ha tempo da perdere", "preferisce i fatti alle parole", "non ne può più della burocrazia" ],
            }
        },
        accessNow: {
            title: ["Accedi a ", { dockington: true }, " e ottieni tutto ciò di cui hai bisogno, ma niente di più."],
            subtitle: "Perché a volte MENO È MEGLIO.",
            cards: [
                {
                    icon: "cube",
                    above: { text: "7", highlight: true },
                    below: "moduli",
                },
                {
                    icon: "file-alt",
                    above: "Documenti",
                    below: { text: "illimitati", highlight: true },
                },
                {
                    icon: { icon: "cloud-upload-alt", style: { width: "1em" } },
                    above: "Backup",
                    below: { text: "giornaliero", highlight: true },
                },
                {
                    icon: [{ icon: "phone", flip: "horizontal" }, "sms", "envelope"],
                    above: "Assistenza",
                    below: { text: "24/7", highlight: true },
                },
            ],
        },
        makeYourOwn: {
            title: ["Personalizza il tuo ", { dockington: true }, "."],
            subtitle: "Prova tutti i moduli gratuitamente, poi scegli quelli che ti servono e dimenticati del resto.",
            modules: [
                {
                    module: "Modulo 1",
                    title: "Contatti",
                    content: {
                        text: "Inserisci le info dei tuoi contatti una volta e basta!",
                        points: [
                            "Ti verranno suggeriti ogni volta che crei un documento.",
                            "Una volta selezionato un contatto, avrai tutti i campi compilati automaticamente per te.",
                            "Sarai in grado di trovare tutti i documenti relativi al contatto in pochi secondi",
                        ],
                    },
                },
                {
                    module: "Modulo 2",
                    title: "Articoli",
                    content: {
                        text: "Inserisci codice, descrizione e prezzo dei tuoi articoli una volta e basta!",
                        points: [
                            "Ti verranno suggeriti ogni volta che crei un documento.",
                            "Una volta selezionato un articolo, avrai tutti i campi compilati automaticamente per te.",
                        ],
                    },
                },
                {
                    module: "Modulo 3",
                    title: "Offerte",
                    content: {
                        text: "Crea un’offerta ogni volta che ti viene richiesto un preventivo e avrai tutto pronto nel caso in cui decidano di ordinare!",
                        points: [
                            "Avrai un archivio di tutte le tue offerte, così vedrai quali prezzi hanno funzionato e quali no, e quando è il momento di aggiornarli.",
                            "Potrai creare ordini direttamente dalle offerte esistenti, senza dover inserire gli stessi dati una seconda volta.",
                        ],
                    },
                },
                {
                    module: "Modulo 4",
                    title: "Ordini",
                    content: {
                        text: "Inserisci i tuoi ordini non appena li ricevi e gestirli sarà una passeggiata!",
                        points: [
                            "Avrai una visione chiara delle tue scadenze (Situazione degli ordini), che ti aiuterà a stabilire le priorità e pianificare il tuo lavoro in anticipo.",
                            "Al momento della consegna, dovrai solo selezionare quali articoli stai consegnando e i DDT saranno pronti in tempo zero.",
                            "Gli ordini evasi verranno automaticamente spostati in Ordini chiusi, così saranno sempre accessibili ma senza infastidirti.",
                            "Gli articoli appena consegnati scompariranno automaticamente dalla Situazione degli ordini, in modo da non confonderti le idee riguardo a ciò che devi fare e ciò che invece hai già fatto.",
                        ],
                    },
                },
                {
                    module: "Modulo 5",
                    title: "DDT",
                    content: {
                        text: "Inizia a creare i tuoi DDT ogni volta che hai qualcosa di pronto o appena prima di consegnare, a te la scelta! In ogni caso, non solo ti ci vorrà pochissimo tempo, ma risparmierai tempo anche per dopo!",
                        points: [
                            "Al momento della fatturazione dovrai solo scegliere il cliente e i DDT che desideri includere, niente di più!",
                            "I DDT appena fatturati verranno automaticamente spostati nei DDT chiusi, così saranno sempre accessibili ma senza infastidirti.",
                            "Avrai la possibilità di creare DDT da non fatturare e archiviarli manualmente.",
                            "Inoltre, se sei una società contoterzista, avrai la possibilità di inserire i DDT di conto lavoro che ricevi, in modo da avere una situazione sempre aggiornata del materiale che hai in azienda (Situazione dei DDT conto lavoro) e le info compilate automaticamente per te ogni volta che consegni della merce.",
                        ],
                    },
                },
                {
                    module: "Modulo 6",
                    title: "Fatture",
                    content: {
                        text: "Crea fatture (di qualsiasi tipo) a partire dai tuoi DDT o da zero.",
                        points: [
                            "Aggiungi sconti a articoli specifici o sul prezzo totale.",
                            "Modifica la % IVA di articoli specifici e indica la legge che lo consente.",
                            "Aggiungi costi speciali non presenti nel tuo elenco di articoli, come i costi di consegna.",
                            "Ottieni i tuoi file XML (fatture elettroniche) e PDF fatti per te.",
                        ],
                    },
                },
                {
                    module: "Modulo 7",
                    title: "CRM",
                    content: {
                        text: "Segui da vicino le abitudini dei tuoi clienti per sapere quando è il momento di farti sentire, creare una promozione o migliorare le tue offerte.",
                        points: [
                            "Ricevi una notifica quando è trascorso troppo tempo dall'ultimo acquisto, e agisci di conseguenza.",
                            "Tieni traccia di quando hai contattato l'ultima volta un cliente e di cosa è uscito fuori dalla vostra chiacchierata, in modo da non rischiare di disturbarlo di nuovo, inutilmente.",
                        ],
                    },
                },
            ],
        },
        forWhom: {
            title: "Per chi è",
            subtitle: "È per due tipi di aziende: l’officina meccanica di precisione e l’e-commerce.",
            artisan: {
                icon: "cogs",
                title: "Per l'officina meccanica di precisione",
                text: [ { dockington: true }, " semplifica la gestione degli ordini, così che tu possa pianificare la produzione in anticipo e assicurare una consegna puntuale ai tuoi clienti. Inoltre, accelera la creazione di DDT e fatture, facendoti spendere il meno tempo possibile con la burocrazia." ],
            },
            retailer: {
                icon: "shopping-cart",
                title: "Per l'e-commerce",
                text: [ { dockington: true }, " accelera il processo di consegna e la creazione delle fatture. In più, ti aiuta a fidelizzare i clienti esistenti dandoti la possibilità di tener traccia delle loro abitudini e quindi formulare offerte irresistibili al momento giusto." ],
            },
        },
        collateral: {
            title: [ "Effetti collaterali di ", { dockington: true } ],
            subtitle: [ { dockington: true }, " può causare una serie di effetti desiderati." ],
            points: [
                "Avrai finalmente più tempo per fare il tuo lavoro, invece di spenderlo a mettere il lavoro su carta.",
                "Avrai ben chiaro le tue scadenze e non ti capiterà più di dare precedenza al cliente che ti stressa di più invece di basarti sull’urgenza reale.",
                "Non avrai più problemi coi corrieri che arrivano all’improvviso a ritirare la merce, perché potrai creare DDT in tempo record.",
                "Non sarai più vago sui tempi di consegna, nemmeno se preso alla sprovvista.",
                "Conoscerai le abitudini dei tuoi clienti così bene che sarà impossibile per loro non comprare di nuovo da te.",
            ],
        },
        whatYouFind: {
            title: [ "Come si presenta " ],
            subtitle: "Ecco alcuni esempi di cosa troverai al suo interno."
        },
        facesBehind: {
            title: [ "Le facce dietro a ", { dockington: true } ],
            messages: [
                { both: [ { h2: "Ciao, piacere! Siamo Elena e Samuli." } ] },
                { elena: [
                    "Io Italiana della provincia di Cuneo, lui Finlandese Lappone.",
                    "Ci siamo ritrovati a fare squadra nel lab di robotica durante l'ultimo anno di Ingegneria dell'Automazione qui a Helsinki, nell'ormai lontano 2014.",
                ] },
                { samuli: [ "Da allora ci divertiamo a fare app e software per quegli imprenditori che si sca**ano a dover fissare la stessa rotellina girare per più di 2s una volta cliccato un pulsante." ] },
                { elena: [ "Per quelli che trovano inconcepibile dover leggere un manuale delle istruzioni stile Bibbia per riuscire a capire come funziona un programma." ] },
                { samuli: [ "Per quelli che si aspettano che i programmi non solo funzionino, ma funzionino come si deve." ] },
                { elena: [ "E per quelli che vorrebbero qualcosa di personalizzato, ma non sono disposti a vendere un rene per poterselo permettere." ] },
            ],
        },
        takeAdvice: {
            title: "Lasciati consigliare",
            subtitle: [ "da chi ", { dockington: true }, " ce l'ha già" ],
            reviews: [
                {
                    male: true,
                    image: {
                        src: "mauro",
                        alt: "",
                    },
                    body: [
                        "Sfido chiunque a trovare un programma del genere, con aggiornamenti continui e piccole personalizzazioni incluse nel prezzo!"
                    ],
                    author: {
                        name: "Mauro",
                        title: [ "Titolare presso ", { outsideLink: "http://ommdimauromargaria.it", text: "O.M.M. di Mauro Margaria" } ],
                    },
                },
                {
                    image: {
                        src: "aurora",
                        alt: "",
                    },
                    body: [
                        "Avevo paura fosse la solita fregatura vista la mia scarsa conoscenza sui programmi, e invece dopo solo pochi giorni dall’inizio del periodo di prova gratis ho constatato di aver trovato l’affare più azzeccato della mia vita."
                    ],
                    author: {
                        name: "Aurora",
                        title: [ "Segretaria presso ", { outsideLink: "http://ommdimauromargaria.it", text: "O.M.M. di Mauro Margaria" } ],
                    },
                },
                {
                    male: true,
                    image: {
                        src: "biancoline",
                        alt: "",
                    },
                    body: [
                        "Facile, veloce e sempre in aggiornamento; gli aggiornamenti sono fatti ", { quote: "ad personam" }, " e l'intero programma viene adeguato secondo le esigenze dell'azienda."
                    ],
                    author: {
                        name: "Fausto",
                        title: [ "Titolare presso ", { outsideLink: "https://www.biancolinenaturalfood.it", text: "Bianco-Line Pet" } ],
                    },
                },
            ],
            cta: "Ti hanno convinto?",
        },
        littleOrMuch: {
            title: [ { b: "Costa poco" }, " o costa tanto?" ],
            subtitle: "Dipende da quanto valore dai al tuo tempo.",
            much: {
                goal: "Non vedi perché dovresti spendere per qualcosa che puoi delegare alla tua segretaria o addirittura fare tu stesso?",
                answer: [ "Allora SÌ, ", { dockington: true }, " costa tanto." ],
            },
            little: {
                goal: "Il tuo obiettivo è quello di dedicare sempre meno tempo alle scartoffie e sempre di più al lavoro vero e proprio?",
                answer: [ "Allora NO, ", { dockington: true }, " costa davvero poco." ],
            },
        },
        packages: {
            title: "Scegli un pacchetto già pronto o crea il tuo.",
            subtitle: "I prezzi non includono l’IVA.",
            pricePerMonth: "€ / mese",
            payYearlyAndSave: "Paga annualmente e risparmia un mese!",
            ready: {
                title: [ { icon: { icon: "cubes", style: { fontSize: "1.25em"}, className: "mr-1" } }, "Pacchetti pronti" ],
                packages: [
                    {
                        name: "Fiat",
                        price: "69",
                        includes: [
                            "Contatti",
                            "Articoli",
                            "DDT",
                            "Fatture (elettroniche)",
                        ],
                    },
                    {
                        name: "Maserati",
                        price: "99",
                        includes: [
                            "Contatti",
                            "Articoli",
                            { b: "Offerte" },
                            { b: "Ordini" },
                            "DDT",
                            "Fatture (elettroniche)",
                        ],
                    },
                    {
                        name: "Lamborghini",
                        price: "119",
                        includes: [
                            "Contatti",
                            "Articoli",
                            "DDT",
                            "Fatture (elettroniche)",
                            { b: "CRM" },
                        ],
                    }
                ],
            },
            single: {
                title: [ { icon: { icon: "cube", className: "mr-1" } }, "Moduli" ],
                modules: [
                    { icon: "address-book", name: "Contatti", price: "0" },
                    { icon: "tags", name: "Articoli", price: "0" },
                    { icon: "comment-dollar", name: "Offerte", price: "0" },
                    { icon: "folder-open", name: "Ordini", price: "30" },
                    { icon: "truck", name: "DDT", price: "30" },
                    { icon: "dollar-sign", name: "Fatture", price: "39" },
                    { icon: "bell", name: "CRM", price: "50" },
                ],
            },
        },
        faq: {
            title: "Le vostre domande frequenti",
            questions: [
                {
                    q: "Se non fa per me?",
                    a: "Nessun problema, amici come prima! Avendo un mese di prova gratis e senza impegno, hai tutto il tempo per capire se fa il caso tuo oppure no, senza dover tirare fuori una lira per qualcosa di cui puoi fare a meno. ",
                },
                {
                    q: "Se ho un problema?",
                    a: [
                        "È decisamente improbabile! Ma se dovesse capitare, mandaci subito un'",
                        { href: "mailto:info@cheeksoft.com", text: "email" },
                        "e avremo una soluzione per te nel giro di 2 ore massimo. ",
                    ],
                },
                {
                    q: "Se manca una funzionalità di cui ho davvero bisogno?",
                    a: "Faccelo sapere assolutamente! Lavoriamo ininterrottamente a nuove funzionalità e la tua potrebbe essere la prossima. ",
                },
                {
                    q: "Se non voglio pagare mensilmente?",
                    a: "Scegli il pagamento annuale e avrai 12 mesi al prezzo di 11! ",
                },
                {
                    q: "Come posso pagare?",
                    a: [
                        "Puoi pagare con carta di credito, carta di debito e domiciliazione bancaria (RID, SEPA Direct Debit). In più, solo nel caso di pagamento annuale, puoi scegliere di pagare con bonifico SEPA. Preferiresti un altro metodo di pagamento? ",
                        { href: "mailto:info@cheeksoft.com", text: "Faccelo sapere!" },
                    ],
                },
                {
                    q: "Perché devo pagare un abbonamento, e non posso comprarlo e averlo per sempre?",
                    a: "Ottima domanda! L’abbonamento ci permette di tenere al sicuro i tuoi dati, rendendoli disponibili ovunque tu sia, e ti dà accesso immediato ai futuri miglioramenti senza bisogno di alcun aggiornamento né costo aggiuntivo. ",
                },
            ],
        },
        login: {
            title: [ "Accedi a ", { dockington: true } ],
            text: [ "Prova tutte le funzionalità ", { b: "gratuitamente per un mese" }, ", senza impegno. Nessuna carta di credito richiesta." ],
            disclaimer: [ "Completando l'accesso accetti le nostre ", { link: "/legal/terms", text: "condizioni" }, " e la nostra ", { link: "/legal/privacy", text: "privacy policy" }, "." ],
            login: "LOGIN",
            loggingIn: "IN CORSO...",
        },
        action: {
            middleTitle: "Ne hai abbastanza di fare copia-incolla?",
            title: "Hai perso abbastanza tempo.",
            button: "PROVA DOCKINGTON GRATIS",
            buttonNav: "ACCEDI ORA",
        },
    },
    testimonials: {
        head: {
            title: "Dockington - Cosa dicono di noi",
            description: "Non perderti i consigli di chi già usa Dockington.",
        },
        tagline: {
            title: "Non crederci sulla parola.",
            subtitle: [ "Credi a chi l'ha provato." ],
        },
        aurora: {
            id: "aurora",
            website: "https://ommdimauromargaria.oneminutesite.it/",
            title: "Provalo!",
            quote: [
                [ "Avevo paura fosse la solita fregatura vista la mia scarsa conoscenza sui programmi, e invece dopo solo pochi giorni dall’inizio del periodo di prova gratis ho constatato di aver trovato ", { emph: "l’affare più azzeccato della mia vita." }],
            ],
            author: {
                name: "Aurora",
                title: "Segretaria presso O.M.M. di Mauro Margaria",
                titleWithLink: [ "Segretaria presso ", { outsideLink: "https://ommdimauromargaria.oneminutesite.it/", text: "O.M.M. di Mauro Margaria" } ],
            },
            full: [
                [ { highlight: "Facile: " }, " essendo intuitivo, trovo subito ciò che mi serve, senza dover cliccare a destra e a manca. E in più, non c’è margine di errore!" ],
                [ { highlight: "Veloce e utile: " }, " con pochissimi click creo e gestisco i miei documenti, risparmiando un sacco di tempo. Ma non solo… dandomi la situazione degli ordini sempre aggiornata in tempo reale, mi aiuta anche a programmare la produzione e a rispettare sempre i tempi di consegna concordati con i clienti. " ],
                [ { highlight: "Affidabile: " }, " in presenza di un problemino me lo hanno risolto in tempo reale, servizio ottimo, professionale e competente ma che mantiene un rapporto familiare pur avendo le potenzialità della grande azienda. Hanno colto le mie reali esigenze migliorando il programma e rendendolo personalizzato in tempi brevissimi e senza costi aggiuntivi! " ],
                "Ultima cosa ma non di importanza: tutto questo lo posso fare da dove voglio: da casa, in ufficio… è sufficiente avere la linea internet!",
                { b: "Lo consiglio a tutti quelle che hanno voglia di migliorare il proprio lavoro e risparmiare tempo!" },
            ]
        },
        mauro: {
            id: "mauro",
            website: "https://ommdimauromargaria.oneminutesite.it/",
            title: "Unico nel suo genere!",
            quote: [
                [ "Sfido chiunque a trovare un programma del genere, con aggiornamenti continui e piccole personalizzazioni incluse nel prezzo!"],
            ],
            author: {
                name: "Mauro",
                title: "Titolare presso O.M.M. di Mauro Margaria",
                titleWithLink: [ "Titolare presso ", { outsideLink: "https://ommdimauromargaria.oneminutesite.it/", text: "O.M.M. di Mauro Margaria" } ],
            },
            full: [
                [ "Dockington è ", { b: "facile da usare" }, " ma allo stesso tempo ", { b: "estremamente intelligente" }, ". Mi permette in pochi click di creare le offerte, le bolle e le fatture, e di avere sempre la situazione aggiornata degli ordini e di conseguenza programmare la produzione. In pratica, grazie a Dockington ", { b: "posso migliorare ogni giorno i servizi ai miei clienti" }, "." ],
                [ "In più, l’", { b: "aggiornamento" }, " è ", { b: "costante" }, " e l’", { b: "assistenza in tempo reale" }, " mi dà la possibilità di personalizzare il programma." ],
                [ "Prezzo che rappresenta un altro punto forte, visto che non richiede ", { b: "alcun grande investimento iniziale" }, "! Infatti, pagando solo quando lo usi, puoi cessare l’abbonamento quando vuoi senza buttare via soldi inutilmente." ],
                { b: "Lo consiglio a tutti quelli che come me non sono così esperti di programmi e che vogliono ottimizzare il lavoro. E anche a chi è spesso fuori sede." }
            ]
        },
        biancoline: {
            id: "biancoline",
            website: "https://www.biancolinenaturalfood.it/",
            title: "PROGRAMMA ECCELLENTE.",
            quote: [
                [ "Facile, veloce e sempre in aggiornamento; gli aggiornamenti sono fatti \"ad personam\" e l'intero programma viene adeguato secondo le esigenze dell'azienda."],
            ],
            author: {
                name: "Fausto",
                title: "Titolare presso Bianco-Line Pet",
                titleWithLink: [ "Titolare presso ", { outsideLink: "https://www.biancolinenaturalfood.it/", text: "Bianco-Line Pet" } ],
            },
            full: [
                "Con l'avvento della fatturazione elettronica a partire dal Gennaio 2019 eravamo alla ricerca di un programma semplice, veloce e funzionale.",
                "Siamo venuti a conoscenza di Dockington tramite e-mail e siamo stati subito catturati dalla semplicità della spiegazione di Elena e dall'ottima tariffa offerta. Abbiamo iniziato il periodo di prova ma da subito siamo stati entusiati del programma.",
                "L'assistenza è rapidissima ed Elena e il suo team sempre molto gentili.",
                "Siamo un realtà artigiana ed avevamo bisogno di un programma semplice e di base. Grazie a loro abbiamo trovato la giusta soluzione.",
                "Complimenti ancora per il vostro lavoro.",
                "Una realtà giovane e intraprendente. PROGRAMMA ECCELLENTE."
            ]
        },
    },
    about: {
        head: {
            title: "Chi siamo",
            description: "Scopri chi c'è dietro a Dockington",
        },
        tagline: {
            title: "Ciao, piacere!",
            subtitle: "Siamo Elena e Samuli, e di lavoro semplifichiamo la vita alle piccole imprese.",
        },
        imgAlt: "Samuli Peurasaari e Elena Margaria",
        elena: {
            name: "Elena Margaria",
            title: "Co-fondatrice, CEO, Sviluppatrice software",
        },
        samuli: {
            name: "Samuli Peurasaari",
            title: "Co-fondatore, CTO, Sviluppatore software",
        },
        messages: [
            { elena: [
                "Io Italiana della provincia di Cuneo, lui Finlandese Lappone.",
                "Ci siamo ritrovati a fare squadra nel lab di robotica durante l'ultimo anno di Ingegneria dell'Automazione qui a Helsinki, nell'ormai lontano 2014."
            ] },
            { samuli: [ "Da allora ci divertiamo a fare app e software per quegli imprenditori che si sca**ano a dover fissare la stessa rotellina girare per più di 2s una volta cliccato un pulsante." ] },
            { elena: [ "Per quelli che trovano inconcepibile dover leggere un manuale delle istruzioni stile Bibbia per riuscire a capire come funziona un programma." ] },
            { samuli: [ "Per quelli che si aspettano che i programmi non solo funzionino, ma funzionino come si deve." ] },
            { elena: [ "E per quelli che vorrebbero qualcosa di personalizzato, ma non sono disposti a vendere un rene per poterselo permettere." ] },
            { both: [ { h2: "Perché sei qui?" } ], className: "mt-3" },
            { elena: [ "Fammi indovinare... Se ti conosco almeno un po', perché sei uno di loro." ] },
            { samuli: [ "Sei stufo di prenderti le solite fregature, abbindolato da chi parla parla parla e poi ti chiede una cifra spropositata per una modifica che in realtà si fa in un'ora." ] },
            { elena: [
                "O sei stufo di quei programmi standard che trovi su internet, che quando non funzionano e hai bisogno di aiuto, neanche pagando riesci a parlare con qualcuno che ti risolva il problema.",
                "Figuriamoci subito!",
            ] },
            { both: [ { h2: "A noi invece piace ascoltare." } ], className: "mt-3" },
            { samuli: [ "Prima, durante e dopo l'esecuzione di un progetto." ] },
            { elena: [ "Prima, perché vogliamo soddisfare le tue esigenze, non le nostre o quelle di qualcun altro." ] },
            { samuli: [ "Durante, perché è solo con un feedback costante che possiamo realizzare le tue idee." ] },
            { elena: [ "Dopo, perché vogliamo assicurarci che tutto sia come te l'eri immaginato: perfetto!" ] },
            { samuli: [ { h2: "Vuoi sapere con chi lavoriamo?" } ], className: "mt-3" },
            { elena: [ "Con micro, piccole e medie imprese che vogliono semplificare la gestione della propria attività, automatizzando le operazioni ripetitive, dalla creazione dei documenti a quella di grafici in Excel." ] },
            { samuli: [ "Con aziende e-commerce, che evadono un ordine dopo l'altro e non possono permettersi di perdere tempo nella creazione di DDT." ] },
            { elena: [ "Con aziende conto terzi, che hanno bisogno di avere ben chiare le priorità e non possono permettersi di sforare i tempi di consegna promessi ai clienti." ] },
            { both: [ { h2: "Insieme, per sempre!" } ], className: "mt-3" },
            { samuli: [ "Quando consegniamo un progetto finito, il rapporto con l'azienda non finisce lì. Inizia." ] },
            { elena: [ "Con alcuni capita anche di sentirci e confrontarci settimanalmente. Perché quando si lavora insieme verso un obiettivo comune, da un'idea ne nasce un'altra e un'altra ancora." ] },
            { samuli: [ { h2: "Non sai da dove iniziare?" } ], className: "mt-3" },
            { elena: [
                [ "Se ne hai le palle piene di perdere tempo in ufficio tra ordini, ddt e fatture dai un'occhiata a ", { link: "/", text: { dockington: true } }, ", il software gestionale fatto su misura per te." ],
                "Non farti ingannare: quelle sono solo le funzionalità di base da cui partire, il resto lo facciamo apposta per te!"
            ] },
            { samuli: [
                [
                    "Se invece cerchi un programma per tenere d'occhio i tuoi conti, senza dover inserire dati su dati in Excel per qualche grafico che ti dia una vaga idea, dai uno sguardo a ",
                    { link: "/dockington-reports", text: { dreports: true } }, ", il software che fa le statistiche al posto tuo."
                ]
            ] },
            { both: [ { h2: "Speriamo di sentirti presto!" } ], className: "mt-3" },
        ],
        signature: "Elena e Samuli",
        company: {
            title: "La nostra startup",
            subtitle: "Ogni volta che hai bisogno di noi, noi ci siamo.",
            map: "Helsinki, Finland",
        }
    },
    signup: {
        head: {
            title: "Dockington Test Drive - Registrati",
            description: "Registrati e ottieni subito il tuo mese gratis di Dockington Test Drive!",
        },
        tagline: {
            title: "Pronto a fare di più in meno tempo?",
            subtitle: [ "Di’ addio alle attività ripetitive." ],
        },
        form: {
            title: [ "Prova ", { dockington: true }, " gratis per un mese, senza impegno." ],
            name: {
                label: "Nome",
                placeholder: "Il tuo nome",
            },
            company: {
                label: "Azienda",
                placeholder: "Il nome della tua azienda",
                comment: "Il nome della tua azienda per il login sarà",
            },
            email: {
                label: "Email",
                placeholder: "Il tuo indirizzo email",
            },
            yes: "SÍ, SONO PRONTO!",
            no: "No, grazie. Mi piace perdere tempo.",
            error: {
                connection: "Si è verificato un problema durante la connessione. Controlla la tua connessione internet e riprova tra qualche minuto.",
                nameInUse: "L'email e/o il nome dell'azienda sono già in uso. Riprova con un'email o un'azienda diversa.",
                internal: "Si è verificato un problema durante la connessione. Riprova di nuovo tra qualche minuto.",
            },
            consent: [
                "Registrandoti accetti le nostre ", { href: "/legal/terms", text: "Condizioni"}, "."
            ],
        },
        details: {
            title: [ "Ecco cosa puoi e non puoi fare con ", { dockington: "Dockington Test Drive" }, ":" ],
            points: [
                { can: true, point: "Salvare tutte le info di clienti e articoli: le inserisci una volta, e le hai autocompilate per te ogni volta che ne hai bisogno." },
                { can: true, point: "Creare offerte, DDT e fatture: solo un paio di click, e i tuoi PDF sono pronti per l’email o la stampa." },
                { can: true, point: "Creare fatture elettroniche senza nemmeno rendertene conto: una volta che la tua fattura è pronta, sei tu a scegliere se scaricare il file PDF, quello XML o entrambi." },
                { can: true, point: "Registrare ordini di vendita e tenerne sotto controllo la situazione: un’occhiata veloce, e sai esattamente cosa c’è da fare." },
                { can: false, point: "Personalizzare i PDF con il logo della tua azienda." },
            ],
        }
    },
    thankyou: {
        head: {
            title: "Dockington Test Drive - Grazie",
            description: "Dockington non ti deluderà, promesso!",
        },
        tagline: {
            title: "SEI UN GRANDE!",
            subtitle: [ "Grazie infinite della fiducia. Dockington non ti deluderà, promesso!" ],
        },
        card: {
            title: "Siamo davvero onorati che pensi possa cambiarti la vita in meglio.",
            advice: [
                "Una mail di benvenuto è in arrivo da parte nostra nella tua casella di posta. Assicurati di leggerla -- ",
                { b: "contiene il link per accedere subito a "} , { dockington: true }, { b: "."}
            ],
            greetings: "Con immensa stima,",
            signature: "Elena e Samuli",
            ps: "P.S. Nessuna mail? Controlla la tua cartella spam!",
        },
        car: "Macchina di ringraziamento",
    },
    notFound: {
        head: {
            title: "404 - Pagina non trovata",
            description: "Oops, pagina non trovata!",
        },
        title: "404 - Oops, pagina non trovata!",
        text: [
            "Qualcosa è andato storto e la pagina che cercavi non può essere visualizzata!",
            [ "Se pensi che sia un errore, ", { text: "faccelo sapere", outsideLink: "mailto:info@cheeksoft.com" }, "!" ],
            [ { text: "Clicca qui", link: "/" }, " per tornare alla home page." ],
        ],
    },
    footer: {
        privacyAndTerms: "Privacy e Condizioni",
    },
    legal: {
        privacy: {
            head: {
                title: "Privacy Policy",
                description: "Scopri la nostra Privacy Policy.",
            },
            title: "Privacy Policy",
            subtitle: "Ultimo aggiornamento: 24 marzo 2019",
            sections: [
                { id: "overview",        name: "1. Panoramica" },
                { id: "what-we-collect", name: "2. I Dati Personali che raccogliamo" },
                { id: "how-we-use",      name: "3. Come usiamo i Dati Personali" },
                { id: "how-we-disclose", name: "4. Come trattiamo i Dati Personali" },
                { id: "your-rights",     name: "5. I tuoi diritti e le tue scelte" },
                { id: "security",        name: "6. Sicurezza e conservazione" },
                { id: "updates",         name: "7. Aggiornamenti a questa Privacy Policy e comunicazioni" },
                { id: "contact",         name: "8. Contattaci" },
            ],
        },
        cookies: {
            head: {
                title: "Cookie Policy",
                description: "Scopri la nostra Cookie Policy.",
            },
            title: "Cookie Policy",
            subtitle: "Ultimo aggiornamento: 24 marzo 2019",
            sections: [
                { id: "how-we-use",            name: "1. Come usiamo i cookie" },
                { id: "other-technologies",    name: "2. Come usiamo altre tecnologie" },
                { id: "how-to-manage-cookies", name: "3. Come gestire i cookie" },
            ],
        },
        terms: {
            head: {
                title: "Termini e Condizioni",
                description: "Scopri le nostre Condizioni Generali.",
            },
            title: "Termini e Condizioni",
            subtitle: "Ultimo aggiornamento: 24 marzo 2019",
            sections: [
                { id: "communications",        name: "1. Comunicazioni" },
                { id: "subscriptions",         name: "2. Abbonamenti" },
                { id: "free-trial",            name: "3. Prova gratuita" },
                { id: "fee-changes",           name: "4. Modifiche prezzi" },
                { id: "refunds",               name: "5. Rimborsi" },
                { id: "exporting-your-data",   name: "6. Esportare i tuoi dati" },
                { id: "content",               name: "7. Contenuto" },
                { id: "accounts",              name: "8. Account" },
                { id: "intellectual-property", name: "9. Proprietà intellettuale" },
                { id: "links",                 name: "10. Link ad altri siti" },
                { id: "termination",           name: "11. Interruzione del servizio" },
                { id: "indemnification",       name: "12. Risarcimento" },
                { id: "liability",             name: "13. Limitazioni di responsabilità" },
                { id: "changes",               name: "14. Aggiornamenti" },
            ],
        },
    },
    cookiepopup: {
        text: [ "Utilizziamo cookie per offrirti la migliore esperienza possibile. Scopri la nostra ", { href: "/legal/cookies", text: "Cookie Policy" }, " per saperne di più." ],
        accept: "Accetto",
    },
    blogs: {
        head: {
            title: "Blog - Dockington",
            description: "I nostri suggerimenti per una vita lavorativa più piacevole.",
        },
        tagline: {
            title: "Lavora in modo più intelligente, non più a lungo",
            subtitle: "I nostri suggerimenti per una vita lavorativa più piacevole.",
        },
        subscribe: {
            miniguide: {
                title: "Perché perdere tempo a fare le statistiche aziendali, quando puoi trovartele fatte GRATIS?",
                text: [
                    "Iscriviti alla nostra newsletter gratuita e ricevi subito gratis ", { dreports: true }, ", il programma che fa le statistiche al posto tuo! (Sembra troppo bello per essere vero? ", { link: "/dockington-reports", text: "Guarda tu stesso!" }, ")",
                ],
                disclaimer: [
                    "Iscrivendoti diventerai un Insider e come tale riceverai i nostri consigli su strumenti e strategie per migliorare il tuo business.", { br: true },
                    "(Niente spam, lo odiamo quanto te.)",
                ],
                subscribe: "IO VOGLIO!",
                subscribing: "IN ARRIVO...",
            },
            title: "Diventa un",
            text: "Riceverai approfondimenti su tecniche e strumenti per migliorare il tuo business.",
            name: "NOME",
            email: "EMAIL",
            subscribe: "ISCRIVITI",
            subscribing: "ISCRIZIONE IN CORSO...",
            miniguideToNumbers: "La mini guida ai tuoi numeri",
            thankyou: {
                imageAlt: "Grazie 1000 per esser diventato un Dockington Insider",
                title: "SEI UN GRANDE!",
                text: [
                    [ "Grazie 1000 per esser diventato un ", { dockington: true }, " Insider! Ne siamo super onorati!!" ],
                    [ "Una mail di benvenuto è in arrivo nella tua casella di posta, insieme al link per scaricare il tuo ", { dreports: true }, "." ],
                ],
                signature: {
                    greeting: "Con stima,",
                    sign: "Elena e Samuli",
                },
                ps: "P.S. Nessuna mail? Controlla la tua cartella spam!",
            },
            error: {
                nameRequired: "Presentati :)",
                emailRequired: "L'indirizzo email è obbligatorio per ricevere gli aggiornamenti via mail.",
                emailInvalid: "L'indirizzo email inserito non è valido. Controlla che sia corretto.",
                connection: "Si è verificato un problema durante la connessione a Dockington. Controlla la tua connessione internet e riprova di nuovo tra qualche minuto.",
                internal: "Si è verificato un problema durante la connessione a Dockington. Riprova di nuovo tra qualche minuto.",
            },
        },
        readmore: "Leggi di più",
        previous: "Precedente",
        next: "Successivo",
        share: "Se l'articolo ti è piaciuto, facci un applauso (o due, perché no!) e condividilo su Facebook. Non sai mai a chi potrebbe tornare utile :)",
        hellobar: {
            formId: "975331",
        },
    },
    reports: {
        head: {
            title: "Dockington Reports",
            description: "Ottieni i tuoi grafici senza alzare un dito e identifica i clienti più preziosi, individua le spese non prettamente necessarie e tieni d'occhio l'andamento di costi e ricavi.",
            keywords: [
                "statistiche costi e ricavi",
                "statistiche di clienti e fornitori",
                "statistiche profitti",
                "statistiche fattura elettronica",
                "grafici costi e ricavi",
                "grafici clienti e fornitori",
            ],
        },
        title: [ "Perché perdere tempo a fare le statistiche aziendali,", { block: "quando puoi trovartele fatte?" } ],
        understanding: {
            title: [ { dreports: true } ],
            subtitle: "Il programma che trasforma le tue fatture XML direttamente in grafici, dandoti la possibilità di avere le tue statistiche sempre pronte, senza dover spendere un solo minuto a farle.",
            text: [
                // [ "Avrai le tue statistiche sempre pronte, senza dover spender un solo minuto a farle." ],
            ],
        },
        solution: {
            includesTitle: "Cosa include",
            includes: [
                [ { b: "Setup facilissimo in meno di 10s" }, ", per poter iniziare subito." ],
                [ { b: "Lettura automatica dei dati direttamente dalle tue fatture XML" }, ", per non dover preoccuparti di inserirli manualmente." ],
                [ { b: "Aggiornamento automatico dei dati" }, ",  perché i tuoi numeri riflettano sempre la realtà." ],
                [ { b: "2 viste distinte per costi / ricavi e clienti / fornitori" }, ", per non farti sopraffare da troppe info, e cogliere immediatamente quelle importanti." ],
                [ { b: "Filtri per mese e anno" }, ", in modo da concentrarti sul periodo che ti interessa e ignorare il resto." ],
                [ { b: "Selezione multipla di clienti e fornitori" }, ", per poterli confrontare senza perdere il filo." ],
                [ { b: "Grafici facili da leggere e ad hoc" }, ", per capire immediatamente cosa sta funzionando e cosa va migliorato." ],
                [ { b: "Legende chiare e suggerimenti utili" }, ", perché le tue decisioni siano basate su numeri reali e non su delle stime imprecise." ],
            ],
            title: "Cosa puoi fare",
            setup: {
                title: "Tieni d'occhio entrate e uscite in tempo reale.",
                text: "Noterai subito se qualcosa non va come dovrebbe. Il che significa che sarai in grado di correggere il tiro prima che sia troppo tardi.",
                imgAlt: "Statistiche costi e ricavi con Dockington Reports",
            },
            visualize: {
                title: "Visualizza da dove arrivano i soldi...",
                text: "Individuerai immediatamente i tuoi clienti più preziosi. E farai chiarezza sulle tue priorità.",
                imgAlt: "Statistiche clienti con Dockington Reports",
            },
            know: {
                title: "... e dove finiscono.",
                text: "Distinguerai facilmente gli sprechi dalle spese necessarie e dagli investimenti. E sarai sempre certo di spendere i tuoi soldi per le cose giuste.",
                imgAlt: "Statistiche fornitori con Dockington Reports",
            },
        },
        proof: {
            aurora: {
                // title: "Give it a try!",
                quote: [
                    "Oh-mio-dio! Non ci posso ancora credere: finalmente niente più dati da inserire in Excel.  Dockington Reports è magico!",
                    // "I recommend it to all of those who want to improve their work, while saving time!",
                ],
                author: {
                    name: "Aurora",
                    title: "Segretaria presso O.M.M. di Mauro Margaria",
                },
            },
        },
        trust: {
            title: "Fa davvero al caso tuo?",
            subtitle: "Ecco alcuni segnali che potrebbero significare di no:",
            points: [
                { reason: "Hai già un programma che visualizza i tuoi numeri in forma di grafici.", comment: "Se ti piace e ne sei soddisfatto, continua con quello: non te ne serve un altro. A meno che tu voglia buttare i soldi." },
                { reason: "Vuoi rimpiazzare la tua segretaria o commercialista.", comment: "A parte il fatto che Dockington Reports non è fatto per tenere la contabilità. Ma poi, è proprio impossibile sostituire la conoscenza di un esperto con un software, e Dockington Reports non fa eccezione. Sì, ti offre una panoramica dei tuoi numeri in tempo reale, ma trarre le conclusioni resta comunque compito di una persona." },
            ],
            objections: [
                {
                    title: "È tutto sul tuo computer: nessuna connessione richiesta",
                    text: [
                        [ { dreports: true } , " è un programma che gira sul tuo computer, per cui non devi caricare le tue fatture XML da nessuna parte." ],
                        "In questo modo, non solo hai il totale controllo su dove tenerle, ma risparmi anche il tempo che impiegheresti a caricarle sul cloud.",
                    ],
                    imgAlt: "Le tue statistiche senza connessione internet",
                },
                {
                    title: "Meriti solo il meglio dal tuo tempo",
                    text: [
                        "Se sono anni che inserisci manualmente i tuoi dati, benvenuto nel club! Non avendo alternativa, per forza eri tu a farlo!",
                        [ "Ma ora la scelta è tua: puoi continuare a perdere ore e ore aggiungendo numeri su Excel oppure lasciare che ", { dreports: true}, " lo faccia al posto tuo mentre tu fai qualcosa di più gratificante." ],
                    ],
                    imgAlt: "Usa il tuo tempo facendo qualcosa di gratificante invece di creare statistiche",
                },
                {
                    title: "Anche la tua segretaria ne sarà felice",
                    text: [
                        "Non sei tu ad inserire i dati? Allora hai una buona ragione in più.",
                        "La tua segretaria sarà entusiasta di potersi finalmente dedicare ad attività che richiedono le sue capacità invece di fare lavori manuali e ripetitivi che chiunque (persino un programma) può fare.",
                    ],
                    imgAlt: "La tua segretaria sarà felice di non dover creare manualmente le statistiche",
                },
            ],
        },
        faq: {
            title: "Domande più frequenti",
            points: [
                // { q: "Per usarlo devo essere connesso a internet?", a: "No! Una volta installato sul computer, lo puoi usare sempre. Anche senza connessione a internet. " },
                { q: "Funziona su qualsiasi computer?", a: "Sì, e con qualsiasi sistema operativo. Una volta ricevuto il link per scaricarlo, puoi scegliere la versione che fa per te tra Windows, Linux e macOS." },
                // { q: "Quanto costa?", a: "30€ (come una serata in pizzeria!). 24€ se lo acquisti come Dockington Insider." },
                // { q: "Come posso pagare?", a: "Con carta Visa, Mastercard o American Express." },
                // { q: "Posso avere la fattura?", a: "Certo! Dopo l'acquisto, rispondi all'email di conferma con le informazioni della tua azienda e te la invieremo nei prossimi 7 giorni." },
                // { q: "Posso richiedere il rimborso?", a: "No, tutte le vendite sono finali." },
                { q: "È davvero gratis?", a: "Sì, una volta che lo scarichi è gratis per sempre. Scaricalo ora, prima che l'offerta finisca!" }
            ],
        },
        reasons: {
            title: "Usa tutta 'sta storia della fattura elettronica a tuo favore per...",
            points: [
                "Visualizzare l'andamento dei tuoi costi e ricavi",
                "Conoscere il tuo profitto mese per mese",
                "Confrontare i tuoi numeri negli anni",
                "Identificare i tuoi clienti più preziosi",
                "Individuare i tuoi costi maggiori",
            ],
            questions: [
                "... Tutto senza nemmeno alzare un dito.",
                "... Alla modica cifra di una serata in pizzeria.",
                "... Senza bisogno di una connessione internet.",
            ],
            text: "Unico requisito: i file XML delle tue fatture, salvati sul tuo pc!"
        },
        signup: {
            button: "SCARICALO GRATIS",
            form: {
                title: "SCARICALO GRATIS",
                subtitle: "",
                text: [
                    "Iscriviti alla nostra newsletter gratuita e ricevi subito il link per scaricarlo. Diventerai un ", { insider: true }, " e come tale riceverai anche i nostri consigli su strumenti e strategie per migliorare il tuo business.",
                ],
                disclaimer: "(Niente spam, lo odiamo quanto te.)",
                subscribe: "LO VOGLIO!",
                subscribing: "IN ARRIVO...",
            },
        },
        form: {
            title: [
                "Acquista ", { dockington: true }, " Reports a",
            ],
            offer: "ACQUISTA ORA",
            price: {
                offer: [ "INSIDER", "OFFERTA SPECIALE" ],
                preorderOffer: [ "PREORDER", "OFFERTA SPECIALE" ],
                expires: "Scade tra",
                original: "45.00 €",
                discounted: "36.00 €",
                preorderOriginal: "30.00 €",
                preorder: "15.00 €",
                expired: "SOLD OUT",
            },
            emailDownloadLink: "Riceverai il link per scaricarlo a questo indirizzo.",
            noCreditCard: "(Carta di credito non richiesta.)",
            disclaimer: {
                highlight: [
                    { b: "Diventa un " }, { dockington: true }, { b: " Insider e ottieni lo sconto immediato del 20%!" },
                ],
                text: [
                    "Riceverai anche approfondimenti su tecniche e strumenti per migliorare il tuo business."
                ],
            },
            thankyou: {
                imageAlt: "Grazie 1000 per esser diventato un Dockington Insider",
                title: "BATTI CINQUE!",
                text: [
                    [ "WOOO, grandissimo!! Grazie 1000 per aver scelto ", { dockington: true }, " Reports! Noi non stiamo più nella pelle: siamo esaltatissimi all'idea che tra te e i tuoi numeri non ci saranno più segreti!" ],
                    [ "Una mail di conferma e la ricevuta di pagamento sono in arrivo nella tua casella di posta. Assicurati di darci un'occhiata -- ", { b: "contengono info importanti." } ],
                ],
                signature: {
                    greeting: "Con stima,",
                    sign: "Elena e Samuli",
                },
                ps: "P.S. Nessuna mail? Controlla la tua cartella spam!",
                visit: {
                    title: "Visita il nostro:",
                    home: "Sito",
                    blog: "Blog",
                },
            },
            thankyouInsider: {
                imageAlt: "Grazie 1000 per esser diventato un Dockington Insider",
                title: "BATTI CINQUE!",
                text: [
                    [ "WOOO, grandissimo!! Grazie 1000 per esser diventato un ", { dockington: true }, " Insider e aver scelto ", { dockington: true }, " Reports. Noi non stiamo più nella pelle: siamo esaltatissimi all'idea che tra te e i tuoi numeri non ci saranno più segreti!" ],
                    [ "Una mail di conferma e la ricevuta di pagamento sono in arrivo nella tua casella di posta. Assicurati di darci un'occhiata -- ", { b: "contengono info importanti." } ],
                ],
                signature: {
                    greeting: "Con stima,",
                    sign: "Elena e Samuli",
                },
                ps: "P.S. Nessuna mail? Controlla la tua cartella spam!",
                visit: {
                    title: "Visita il nostro:",
                    home: "Sito",
                    blog: "Blog",
                },
            },
        },
        buy: {
            full: "Buy without signing up",
            preorder: "Buy now with 50% discount",
        },
        download: {
            head: {
                title: "Scarica Dockington Reports",
                description: "Scarica Dockington Reports",
            },
            title: [ "Scarica ", { dockington: true }, " Reports" ],
            selectPlatform: "Seleziona la tua piattaforma",
            linux: {
                title: "Linux",
                body: "Compatibile con Ubuntu 12.04, Fedora 21 e Debian 8, e successivi",
                install: [
                    "Scarica il file .AppImage cliccando sul pulsante arancione qui sopra.",
                    [ "Segui ", { outsideLink: "https://wiki.ubuntu-it.org/AmministrazioneSistema/InstallareProgrammi/PacchettiAppImage", text: "queste istruzioni" }, " per completare l'installazione." ],
                ],
            },
            windows: {
                title: "Windows",
                body: "Compatibile con 32 e 64-bit Windows 7, 8 e 10",
                install: [
                    "Scarica il file eseguibile .exe cliccando sul pulsante blu qui sopra.",
                    "Fai doppio clic sul file .exe appena scaricato e segui le istruzioni.",
                ],
            },
            mac: {
                title: "macOS",
                body: "Compatibile con macOS 10.10 Yosemite e successivi",
                install: [
                    "Scarica il file .dmg cliccando sul pulsante argento qui sopra.",
                    [ "Fai doppio clic sul file .dmg appena scaricato e trascina l'icona di ", { dockington: true }, " Reports nella cartella Applicazioni." ],
                ],
            },
            installationTitle: "Istruzioni per l'installazione",
            help: [
                "Se dovessi avere problemi nell'installazione o esecuzione di ", { dockington: true }, " Reports, non esitare a scriverci a ", { outsideLink: "mailto:dockington@cheeksoft.com", text: "dockington@cheeksoft.com" }, ".",
            ],
            loading: "Caricamento...",
        },
    },
    common: {
        daysAbbreviation: "GG",
        button: {
            close: "Chiudi",
        },
        shareOnFacebook: "Condividi su Facebook",
        forms: {
            company: "Azienda",
            name: "Nome",
            email: "Email",
            subscribe: "ISCRIVITI",
            subscribing: "ISCRIZIONE IN CORSO...",
            placeholder: {
                name: "Il tuo nome",
                company: "Il nome della tua azienda",
                email: "Il tuo indirizzo email",
            },
            error: {
                nameRequired: "Presentati :)",
                emailRequired: "L'indirizzo email è obbligatorio per ricevere gli aggiornamenti via mail.",
                companyRequired: "Il nome dell’azienda deve contenere almeno 3 lettere.",
                emailInvalid: "L'indirizzo email inserito non è valido. Controlla che sia corretto.",
                connection: "Si è verificato un problema durante la connessione a Dockington. Controlla la tua connessione internet e riprova di nuovo tra qualche minuto.",
                internal: "Si è verificato un problema durante la connessione a Dockington. Riprova di nuovo tra qualche minuto.",
            },
        },
    },
};

export default italian;
